import React, { useState, useEffect, useContext } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoCloseCircleSharp } from "react-icons/io5";
import StatusTicks from "./StatusTicks";
import MessageDialog from "./MessageDialog";
import ReactionDialog from "./ReactionDialog";
import ReactionManagementDialog from "./ReactionManagementDialog";
import { imgUrl, postRequest, deleteRequest } from "../utils/services";
import { encryptMessage, decryptMessage } from "../utils/MessageEncryptDecrypt";
import { RiShareForwardFill } from "react-icons/ri";
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
} from "react-icons/fa";
import PollMessage from "./PollMessage";
import { AuthContext } from "../context/AuthContext";
import { ConversationContext } from "../context/ConversationContext";
import { format, isValid } from "date-fns";

function MessageBubble({
  msg = {},
  message,
  mId,
  type,
  time,
  isLink,
  img,
  audio,
  sent,
  status,
  profileImage,
  onProfilePicClick,
  onEditMessage,
  onDeleteMessage,
  onPinMessage,
  onReplyMessage,
  replyingTo,
  files = [],
  poll,
  onUnpinMessage,
  isPinned,
  reactions = [],
  previousMessage,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [editMessage, setEditMessage] = useState(msg);
  const [isEditing, setIsEditing] = useState(false);
  const [isReactionDialogOpen, setIsReactionDialogOpen] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReactionManagementDialogOpen, setIsReactionManagementDialogOpen] =
    useState(false);
  const [isForwardModalOpen, setIsForwardModalOpen] = useState(false);
  const [selectedConversationIds, setSelectedConversationIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useContext(AuthContext);
  const { userConversations } = useContext(ConversationContext);

  const currentDate = new Date(message.createdAt);
  const previousDate = previousMessage
    ? new Date(previousMessage.createdAt)
    : null;
  const showDateLabel =
    !previousDate ||
    (isValid(currentDate) &&
      isValid(previousDate) &&
      format(currentDate, "yyyy-MM-dd") !== format(previousDate, "yyyy-MM-dd"));

  // const currentUserId = "66ea4fc6292786c8e614e3c3";
  const currentUserId = user._id;

  const handleVote = (pollIndex, optionIndex) => {
    console.log(`Poll ${pollIndex} option ${optionIndex} selected`);
  };

  const handleIconClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleEditMessage = () => {
    setIsEditing(true);
    setIsDialogOpen(false);
  };

  const handleMessageChange = (e) => {
    setEditMessage(e.target.value);
  };

  const handleMessageSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const encryptedMessage = encryptMessage(editMessage);
      onEditMessage(encryptedMessage);
      setIsEditing(false);
    }
  };

  const handleReply = () => {
    onReplyMessage({ id: msg.id, msg });
  };

  const handleDelete = (option) => {
    onDeleteMessage(option);
    setIsDialogOpen(false);
    setSelectedReaction(null);
  };

  const handleProfileClick = () => {
    setIsProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setIsProfileDialogOpen(false);
  };

  const handleReact = () => {
    if (selectedReaction) {
      handleReactionRemove();
    } else {
      setIsReactionDialogOpen(true);
    }
  };

  const handleReactionSelect = (reaction) => {
    handleReactionAdd(reaction);
    setIsReactionDialogOpen(false);
  };

  const handleCloseReactionDialog = () => {
    setIsReactionDialogOpen(false);
  };

  const handleReactionManagementClick = () => {
    setIsReactionManagementDialogOpen(!isReactionManagementDialogOpen);
  };

  const handleReactionAdd = async (reaction) => {
    console.log(`msg: ${mId}`);
    try {
      const response = await postRequest(`/messages/reaction/${mId}`, {
        reaction,
      });
      if (!response.error) {
        setSelectedReaction(reaction);
      }
    } catch (error) {
      console.error("Error adding reaction:", error);
    }
  };

  const handleReactionRemove = async () => {
    try {
      const response = await deleteRequest(`/messages/reaction/${mId}`);
      if (!response.error) {
        setSelectedReaction(null);
      }
    } catch (error) {
      console.error("Error removing reaction:", error);
    }
  };

  const handleRemoveReaction = () => {
    setSelectedReaction(null);
  };

  const handleCloseReactionManagementDialog = () => {
    setIsReactionManagementDialogOpen(false);
  };

  const handlePinToggle = () => {
    if (isPinned) {
      if (typeof onUnpinMessage === "function") {
        onUnpinMessage(msg._id);
      } else {
        console.error("onUnpinMessage function error.");
      }
    } else {
      if (typeof onPinMessage === "function") {
        onPinMessage();
      } else {
        console.error("onPinMessage function error.");
      }
    }
    setIsDialogOpen(false);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <FaFilePdf className="text-red-600" />;
      case "doc":
      case "docx":
        return <FaFileWord className="text-blue-600" />;
      case "xls":
      case "xlsx":
        return <FaFileExcel className="text-green-600" />;
      case "ppt":
      case "pptx":
        return <FaFilePowerpoint className="text-orange-600" />;
      default:
        return <FaFileImage className="text-amber-300" />;
    }
  };

  const handleForwardMessage = async () => {
    if (selectedConversationIds.length === 0) {
      alert("Please select at least one conversation to forward the message.");
      return;
    }

    try {
      const response = await postRequest("/messages/forward", {
        messageId: mId,
        conversationIds: selectedConversationIds,
      });

      if (response.success) {
        console.log(
          "Message forwarded successfully:",
          response.forwardedMessages
        );
      } else {
        console.error("Failed to forward message:", response.error);
      }
    } catch (error) {
      console.error("Error forwarding message:", error);
    }

    closeAndReset();
  };

  const handleCheckboxChange = (conversationId) => {
    setSelectedConversationIds((prevSelected) => {
      if (prevSelected.includes(conversationId)) {
        // If already selected, remove it
        return prevSelected.filter((id) => id !== conversationId);
      } else {
        // Otherwise, add it
        return [...prevSelected, conversationId];
      }
    });
  };

  const messageMargin = selectedReaction ? "mb-4" : "my-2";
  const isImage =
    img || (files.length > 0 && files[0]?.type?.startsWith("image/"));

  const messageType = isImage
    ? "image"
    : files.length > 0
    ? "file"
    : audio
    ? "audio"
    : isLink
    ? "link"
    : poll
    ? "poll"
    : "text";

  const isDeleted = msg && typeof msg === "object" && msg.isDeleted;

  const userReaction = reactions.find(
    (reaction) => reaction.userId === currentUserId
  );

  const handleReactionClick = (reaction) => {
    if (reaction.userId === currentUserId) {
      handleReactionRemove();
    } else {
      setSelectedReaction(reaction.reaction);
    }
  };

  const closeAndReset = () => {
    setIsForwardModalOpen(false);
    setSelectedConversationIds([]);
  };

  const forwardMessage = () => {
    setIsForwardModalOpen(true);
    handleCloseDialog();
  };

  return (
    <React.Fragment>
      {showDateLabel && isValid(currentDate) && (
        <div className="text-xs text-center text-gray-500 my-3">
          {format(currentDate, "dd/MM/yyyy")}
        </div>
      )}
      <div
        className={`relative flex flex-col rounded-md p-2 mb-2 ${
          sent ? "bg-[#005c4b] ml-auto" : "bg-[#202d33] mr-auto"
        }`}
        style={{
          width: "fit-content",
          minWidth: "90px",
          maxWidth: "48%",
          marginTop: "30px",
        }}
      >
        {message.isForwarded && (
          <div className="flex items-center gap-1 text-xs text-gray-400 font-medium italic mb-1">
            <div>
              <RiShareForwardFill />
            </div>
            <div>Forwarded</div>
          </div>
        )}
        <div className="relative flex flex-row items-center">
          {!sent && (
            <div className="relative">
              <img
                src={`${imgUrl}${profileImage}`}
                alt="User Profile"
                className="w-8 h-6 rounded-full mr-2 cursor-pointer"
                onClick={handleProfileClick}
              />
              {isProfileDialogOpen && (
                <div className="absolute top-10 left-0 bg-opacity- p-4 rounded-md shadow-md w-48 z-50">
                  <div className="relative">
                    <img
                      src={`${imgUrl}${profileImage}`}
                      alt="Profile"
                      className="w-24 h-24 rounded-full mx-auto"
                    />
                    <IoCloseCircleSharp
                      className="absolute top-1 right-1 text-red-500 text-xl cursor-pointer"
                      onClick={handleCloseProfileDialog}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex flex-col w-full h-full ">
            <div
              className="flex flex-col w-full h-full ml-6"
              style={{ wordBreak: "break-word" }}
            >
              {isEditing ? (
                <textarea
                  value={editMessage}
                  onChange={handleMessageChange}
                  onKeyDown={handleMessageSubmit}
                  className="w-full p-2 rounded-md text-white bg-[#202D33]"
                  autoFocus
                />
              ) : isImage ? (
                <div className="relative w-100">
                  <img
                    src={img || files[0]?.url}
                    alt="message_content"
                    className="rounded-md max-w-[270px] w-100"
                  />
                  <a
                    href={img || files[0]?.url}
                    download
                    className="absolute bottom-2 right-2 text-white text-xs underline"
                  >
                    Download
                  </a>
                </div>
              ) : files.length > 0 ? (
                <div className="flex flex-col items-center">
                  {files.map((file) => (
                    <div key={file._id} className="flex flex-col items-center">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="text-2xl">
                          {getFileIcon(file.filename)}
                        </div>
                        <div className="text-gray-200">{file.filename}</div>
                      </div>
                      <div className="w-full">
                        <a
                          href={file.url}
                          download
                          className="block bg-[#33796E] hover:bg-[#004b3b] text-white text-sm font-semibold py-2 px-4 rounded-md w-full text-center focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50 transition duration-300 ease-in-out"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              ) : poll ? (
                <div className="flex flex-col">
                  <PollMessage
                    poll={poll}
                    onVote={(pollId, optionIndex) =>
                      handleVote(pollId, optionIndex)
                    }
                    sent={sent}
                  />
                </div>
              ) : audio ? (
                <div className="flex flex-col items-start">
                  <audio controls className="rounded-md max-w-[270px] w-300">
                    <source src={audio} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ) : isLink ? (
                <a
                  href={msg}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline"
                >
                  {msg}
                </a>
              ) : (
                <p
                  className={`text-white text-sm mr-2 ${
                    isDeleted ? "text-gray-500" : ""
                  }`}
                >
                  {isDeleted ? "This message was deleted" : msg}
                </p>
              )}
              {isForwardModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                  <div className="bg-[#202d33] p-4 rounded-lg shadow-lg w-[400px] h-[510px] relative">
                    {/* Close Button in the Right Corner */}
                    <button
                      className="absolute top-3 right-3 text-white text-2xl hover:text-gray-400 focus:outline-none"
                      onClick={closeAndReset}
                    >
                      &times;
                    </button>

                    {/* Title */}
                    <h2 className="text-white text-lg mb-4">Forward to...</h2>

                    {/* Search Input */}
                    <input
                      type="text"
                      className="w-full p-2 rounded bg-[#111a21] text-white mb-3"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    {/* Scrollable List */}
                    <div className="overflow-y-auto h-[350px] mb-4">
                      {userConversations
                        .filter((contact) =>
                          contact.chatName
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((contact, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between text-white mb-2 cursor-pointer p-2 hover:bg-[#3c454c] rounded-lg"
                          >
                            <div className="flex items-center">
                              <img
                                src={`${imgUrl}${
                                  contact.chatIcon ||
                                  "/path/to/default/avatar.jpg"
                                }`}
                                alt="Contact"
                                className="w-8 h-8 rounded-full mr-2"
                              />
                              <p>
                                {contact.chatName ||
                                  contact.participants[0]?.name ||
                                  "Unnamed Chat"}
                              </p>
                            </div>
                            <input
                              type="checkbox"
                              className="form-checkbox text-emerald-500"
                              checked={selectedConversationIds.includes(
                                contact._id
                              )}
                              onChange={() => handleCheckboxChange(contact._id)}
                            />
                          </div>
                        ))}
                    </div>

                    {/* Forward Button */}
                    <button
                      onClick={handleForwardMessage}
                      className="bg-emerald-500 text-white py-2 px-4 rounded w-full"
                    >
                      Forward
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between items-center ml-2">
            <div className="flex-grow"></div>
            <div className="w-6 h-6" onClick={handleIconClick}>
              <IoIosArrowDropdownCircle className="text-[#8796a1] text-xl cursor-pointer" />
            </div>
          </div>
        </div>

        {reactions.length > 0 && (
          <div
            className={`absolute -bottom-0 ${
              sent ? "-left-2" : "-right-2"
            } text-xl flex`}
            style={{ transform: "translateY(100%)" }}
          >
            {reactions.map((reaction) => (
              <div
                key={reaction._id}
                className={`text-base cursor-pointer ${
                  sent ? "-ml-1" : "-mr-1"
                }`}
                onClick={() => handleReactionClick(reaction)}
              >
                {reaction.reaction}
              </div>
            ))}
          </div>
        )}

        {isDialogOpen && (
          <MessageDialog
            isSent={sent}
            onClose={handleCloseDialog}
            onEdit={handleEditMessage}
            onDelete={handleDelete}
            onPinMessage={onPinMessage}
            onForward={forwardMessage}
            onReply={handleReply}
            messageId={msg._id}
            message={{ msg: msg?.content || "", time, sent }}
            onReact={handleReact}
            messageType={messageType}
            onPin={handlePinToggle}
            isPinned={isPinned}
          />
        )}

        {isReactionDialogOpen && (
          <ReactionDialog
            onClose={handleCloseReactionDialog}
            onSelectReaction={handleReactionSelect}
            position={sent ? "left" : "right"}
          />
        )}

        {isReactionManagementDialogOpen && (
          <ReactionManagementDialog
            reaction={selectedReaction}
            onRemoveReaction={handleRemoveReaction}
            onClose={handleCloseReactionManagementDialog}
          />
        )}

        <div className="flex justify-between ">
          <div className="mt-2 -mb-1">
            <StatusTicks status={status} />
          </div>
          <div className="absolute right-2 bottom-1 text-[#c0c6cb] text-[10px]">
            {time}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageBubble;
