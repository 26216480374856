import React, { useEffect, useRef } from "react";

function ReactionDialog({ onClose, onSelectReaction, position }) {
  const reactions = ["👍", "❤️", "😂", "😮", "😢", "😡"];
  const dialogRef = useRef(null);

  const handleReactionClick = (reaction) => {
    onSelectReaction(reaction);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={dialogRef}
      className={`absolute z-50 top-0 ${
        position === "left" ? "right-0" : "left-0"
      } mt-2 border rounded-full shadow-lg bg-white`}
    >
      <div className="flex space-x-2 p-4">
        {reactions.map((reaction) => (
          <button
            key={reaction}
            onClick={() => handleReactionClick(reaction)}
            className="text-2xl"
          >
            {reaction}
          </button>
        ))}
      </div>
    </div>
  );
}

export default ReactionDialog;

// import React, { useEffect, useRef } from "react";

// function ReactionDialog({ onClose, onSelectReaction }) {
//   const reactions = ["👍", "❤️", "😂", "😮", "😢", "😡"];
//   const dialogRef = useRef(null);

//   useEffect(() => {
//     const dialogElement = dialogRef.current;
//     const rect = dialogElement.getBoundingClientRect();

//     // If the dialog is going off the screen, adjust its position
//     if (rect.right > window.innerWidth) {
//       dialogElement.style.left = `${window.innerWidth - rect.width - 20}px`; // Adjust left position
//     }

//     if (rect.bottom > window.innerHeight) {
//       dialogElement.style.top = `${window.innerHeight - rect.height - 20}px`; // Adjust top position
//     }

//     const handleClickOutside = (event) => {
//       if (dialogRef.current && !dialogRef.current.contains(event.target)) {
//         onClose();
//       }
//     };
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [onClose]);

//   return (
//     <div
//       ref={dialogRef}
//       className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-full shadow-lg bg-white"
//     >
//       <div className="flex space-x-2 p-4">
//         {reactions.map((reaction) => (
//           <button
//             key={reaction}
//             onClick={() => onSelectReaction(reaction)}
//             className="text-2xl"
//           >
//             {reaction}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ReactionDialog;

// // import React, { useEffect, useRef } from "react";

// // function ReactionDialog({ onClose, onSelectReaction }) {
// //   const reactions = ["👍", "❤️", "😂", "😮", "😢", "😡"];
// //   const dialogRef = useRef(null);

// //   const handleReactionClick = (reaction) => {
// //     onSelectReaction(reaction);
// //     onClose();
// //   };

// //   useEffect(() => {
// //     const handleClickOutside = (event) => {
// //       if (dialogRef.current && !dialogRef.current.contains(event.target)) {
// //         onClose();
// //       }
// //     };
// //     document.addEventListener("mousedown", handleClickOutside);
// //     return () => {
// //       document.removeEventListener("mousedown", handleClickOutside);
// //     };
// //   }, [onClose]);

// //   return (
// //     <div
// //       ref={dialogRef}
// //       className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-full shadow-lg bg-white"
// //     >
// //       <div className="flex space-x-2 p-4">
// //         {reactions.map((reaction) => (
// //           <button
// //             key={reaction}
// //             onClick={() => handleReactionClick(reaction)}
// //             className="text-2xl"
// //           >
// //             {reaction}
// //           </button>
// //         ))}
// //       </div>
// //     </div>
// //   );
// // }

// // export default ReactionDialog;
