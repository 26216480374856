import React, { useEffect, useRef } from "react";

function ReactionManagementDialog({ reaction, onRemoveReaction, onClose }) {
  const dialogRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dialogRef}
      className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-md shadow-lg bg-white p-4"
    >
      <div className="flex items-center space-x-2">
        <span className="text-2xl">{reaction}</span>
        <button
          onClick={() => {
            onRemoveReaction();
            onClose();
          }}
          className="text-red-500"
        >
          Remove
        </button>
      </div>
    </div>
  );
}

export default ReactionManagementDialog;
