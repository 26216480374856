import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoCalendarSharp } from "react-icons/io5";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const SearchPopup = ({ onClose, messages }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    const filteredResults = messages.filter((message) => {
      const msg = message.msg || "";
      return msg.toLowerCase().includes(query.toLowerCase());
    });
    setResults(filteredResults);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-[#222f35] p-4 z-10">
      <div className="flex items-center mb-4">
        {/* <button onClick={toggleCalendar} className="text-white text-xl mr-4">
          <IoCalendarSharp />
        </button> */}
        <button onClick={onClose} className="text-white text-xl mr-4">
          <FaArrowRight />
        </button>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search..."
          className="flex-1 p-2 bg-[#1c2730] text-white rounded"
        />
      </div>
      <div className="overflow-y-auto h-full">
        {results.length > 0 ? (
          results.map((result, index) => (
            <div
              key={index}
              className="p-2 text-white border-b border-gray-600"
            >
              {result.msg}
            </div>
          ))
        ) : (
          <div className="p-2 text-white">No results found</div>
        )}
      </div>
      {showCalendar && (
        <div className="absolute top-16 right-4 bg-[#1c2730] p-4 rounded z-20">
          <Calendar className="bg-[#1c2730] text-white" />
        </div>
      )}
    </div>
  );
};

export default SearchPopup;
