import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { imgUrl } from "../utils/services";

const ParticipantsList = ({ participants, groupAdmins, onPromote, onRemove, currentUserId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const handleDropdownToggle = (userId) => {
    setDropdownOpen(dropdownOpen === userId ? null : userId);
  };

  const handlePromote = (userId) => {
    onPromote(userId);
    setDropdownOpen(null);
  };

  const handleRemove = (userId) => {
    onRemove(userId);
    setDropdownOpen(null);
  };

  const sortedParticipants = participants.sort((a, b) => {
    const aIsAdmin = groupAdmins.includes(a._id);
    const bIsAdmin = groupAdmins.includes(b._id);

    if (aIsAdmin && !bIsAdmin) return -1;
    if (!aIsAdmin && bIsAdmin) return 1;

    return a.username.localeCompare(b.username);
  });

  return (
    <div>
      {sortedParticipants.map((participant) => (
        <div key={participant._id} className="flex items-center justify-between p-3 border-b border-gray-700">
          <div className="flex items-center">
            <img
              src={`${imgUrl}${participant.profilePicture || '/assets/images/default_profile.jpg'}`}
              alt="profile_picture"
              className="rounded-full w-[40px] h-[40px] mr-2"
            />
            <div className="flex flex-col">
              <span className="text-white font-medium">{participant.username}</span>
              <span className="text-xs text-gray-400">{participant.mobile || 'N/A'}</span>
            </div>
          </div>
          <div className="relative">
            {groupAdmins.includes(participant._id) && (
              <span className="text-xs text-white font-sans bg-[#4c5d69] px-2 py-1 rounded">Group admin</span>
            )}
            {groupAdmins.includes(currentUserId) && (
              <button className="text-white ml-2" onClick={() => handleDropdownToggle(participant._id)}>
                <AiOutlineDown />
              </button>
            )}
            {dropdownOpen === participant._id && (
              <div className="absolute right-0 mt-2 w-40 bg-gray-800 text-white rounded shadow-lg">
                {!groupAdmins.includes(participant._id) && (
                  <div className="p-2 cursor-pointer hover:bg-gray-700" onClick={() => handlePromote(participant._id)}>
                    Promote to Admin
                  </div>
                )}
                <div className="p-2 cursor-pointer hover:bg-gray-700" onClick={() => handleRemove(participant._id)}>
                  Remove from Group
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ParticipantsList;
