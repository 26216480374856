import React, { useEffect, useState, useRef, useContext } from "react";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { HiDotsVertical } from "react-icons/hi";
import { MdPeopleAlt, MdAccountCircle } from "react-icons/md";
import { IoPersonCircle } from "react-icons/io5";
import { TbCircleDashed } from "react-icons/tb";
import { FiPlus } from "react-icons/fi";
import { GoCheckCircleFill } from "react-icons/go";
import Chats from "./Chats";
import StatusList from "./StatusList";
import RoundedBtn from "./Common/RoundedBtn";
import DropDownMenu from "./Common/DropDownMenu";
import Archived from "./Archived";
import Settings from "./Settings";
import UserProfile from "./UserProfile";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import ContactsList from "./ContactsList";
import { ConversationContext } from "../context/ConversationContext";
import AllContact from "../components/AllContact";
import { logout, getRequest, imgUrl } from "../utils/services";

function LeftMenu({ onChatSelect, userEmail, user, chatsUsers }) {
  const { createGroup } = useContext(ConversationContext);
  const [filter, setFilter] = useState(false);
  const [currentView, setCurrentView] = useState("chats");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePic, setProfilePic] = useState(user.profilePicture);
  const [search, setSearch] = useState("");
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [isGroupNameInputVisible, setIsGroupNameInputVisible] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [groupIcon, setGroupIcon] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [isAddChatDrawerOpen, setIsAddChatDrawerOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("Chats");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const dropdownRef = useRef(null);
  const fileInputRef = useRef(null);

  const toggleAllUsersColumn = () => {
    setIsAddChatDrawerOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const conversations = sortConversationsByDate(chatsUsers);
    setConversations(conversations);
  }, [chatsUsers]);

  const sortConversationsByDate = (conversations) => {
    const userId = user._id;

    const filteredConversations = conversations.filter(
      (conversation) => !conversation.archivedBy?.includes(userId)
    );

    return filteredConversations.sort((a, b) => {
      const aPinned = a.pinnedBy?.includes(userId);
      const bPinned = b.pinnedBy?.includes(userId);

      if (aPinned && !bPinned) return -1;
      if (!aPinned && bPinned) return 1;

      const dateA = new Date(a.lastMessageTime || a.updatedAt || a.createdAt);
      const dateB = new Date(b.lastMessageTime || b.updatedAt || b.createdAt);

      if (!a.lastMessageTime && b.lastMessageTime) return 1;
      if (a.lastMessageTime && !b.lastMessageTime) return -1;

      return dateB - dateA;
    });
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      const response = await getRequest("/users/");
      if (!response.error) {
        setAllUsers(response);
      } else {
        console.error("Failed to fetch sidebar users:", response.message);
      }
    };
    fetchAllUsers();
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownClick = (event) => {
    setDropdownPosition({ top: event.clientY, left: event.clientX });
    setDropdownVisible(!dropdownVisible);
  };

  const handleFilterToggle = () => {
    setFilter(!filter);
  };

  const handleViewChange = async (view) => {
    if (view === "status") {
      setActiveButton("Status");
    } else if (view === "chats") {
      setActiveButton("Chats");
    } else {
      setActiveButton(null);
    }
    setCurrentView(view);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat._id);
    onChatSelect(chat);
  };

  const handleSelectContact = (contactId) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(contactId)
        ? prevSelected.filter((id) => id !== contactId)
        : [...prevSelected, contactId]
    );
  };

  const handleCreateGroup = () => {
    setIsDrawerOpen(true);
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
      fileInputRef.current.click();
    }
  };

  const handleRemoveIcon = () => {
    setGroupIcon(null);
    fileInputRef.current.value = null;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setGroupIcon({
        file: file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  const handleGroupCreation = async () => {
    if (groupName.trim() !== "") {
      createGroup(selectedContacts, groupName, groupIcon);
      setIsGroupNameInputVisible(false);
      setSelectedContacts([]);
      setGroupName("");
      setGroupIcon(null);
      setIsDrawerOpen(false);
    } else {
      alert("Group name cannot be empty.");
    }
    setRenderTrigger((prev) => !prev);
  };

  const handleArchivedClick = () => {
    setActiveButton(null);
    setCurrentView("archived");
  };

  const handleProfileClick = () => {
    setIsModalOpen(true);
  };

  const updateProfilePicture = (pic) => {
    setProfilePic(pic);
  };

  const handleSettings = () => {
    setCurrentView("settings");
  };

  const handleLogout = async () => {
    const response = await logout();
    if (!response.error) {
      console.log("Logged out successfully");
      window.location.href = "/login";
    } else {
      console.error("Logout failed:", response.message);
    }
  };

  const dropdownOptions = [
    { label: "Settings", onClick: handleSettings },
    { label: "Logout", onClick: handleLogout },
  ];

  return (
    <div className="flex flex-col border-r border-neutral-700 w-100 h-screen">
      <UserProfile
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        profilePic={`${imgUrl}${profilePic}`}
        onUpdateProfilePicture={updateProfilePicture}
        user={user}
      />
      <div className="flex justify-between items-center bg-[#202d33] h-[60px] p-3 relative">
        <div className="flex justify-center items-center gap-2">
          {profilePic ? (
            <img
              src={`${imgUrl}${profilePic}`}
              alt="profile_picture"
              className="rounded-full w-[40px] cursor-pointer"
              onClick={handleProfileClick}
            />
          ) : (
            <MdAccountCircle
              className="text-[#8796a1] hover:bg-[#3c454c] hover:rounded-full w-[40px] h-[40px] cursor-pointer"
              onClick={handleProfileClick}
            />
          )}
          <p className="text-white text-sm">{userEmail}</p>
        </div>
        <div className="flex justify-between -mr-2">
          <RoundedBtn
            icon={<MdPeopleAlt />}
            onClick={handleCreateGroup}
            title="Groups"
          />
          <RoundedBtn
            icon={<TbCircleDashed />}
            onClick={() => {
              handleButtonClick("Status");
              handleViewChange("status");
            }}
            className={activeButton === "Status" ? "bg-[#3c454c]" : ""}
            title="Status"
          />
          <RoundedBtn
            icon={<BsFillChatLeftTextFill />}
            onClick={() => {
              handleButtonClick("Chats");
              handleViewChange("chats");
            }}
            className={activeButton === "Chats" ? "bg-[#3c454c]" : ""}
            title="Chats"
          />
          <RoundedBtn
            icon={<FiPlus />}
            onClick={() => toggleAllUsersColumn("addToChats")}
            title="addToChats"
          />
          <div ref={dropdownRef}>
            <RoundedBtn
              icon={<HiDotsVertical />}
              onClick={handleDropdownClick}
              title="Menu"
            />
            {dropdownVisible && (
              <DropDownMenu
                options={dropdownOptions}
                position={dropdownPosition}
                onClose={() => setDropdownVisible(false)}
              />
            )}
          </div>
        </div>
      </div>
      {currentView === "chats" && (
        <Chats
          key={chatsUsers.length}
          filter={false}
          users={conversations}
          onChatSelect={handleChatClick}
          onArchivedClick={handleArchivedClick}
          archived={chatsUsers.length - conversations.length}
        />
      )}
      {currentView === "settings" && (
        <Settings
          profilePic={`${imgUrl}${profilePic}`}
          handleProfileClick={handleProfileClick}
          handleLogout={handleLogout}
          user={user}
        />
      )}
      {currentView === "status" && <StatusList />}
      {currentView === "archived" && (
        <Archived onChatSelect={handleChatClick} />
      )}
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        direction="left"
        className="drawer"
        style={{
          width: "400px",
          backgroundColor: "#111a21",
          backgroundColor: "#202d33",
        }}
      >
        <div className="bg-[#111a21] w-full h-full p-4 relative">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-white text-lg">Select Contacts</h2>
            <button
              className="text-white text-lg hover:bg-slate-700 rounded-full px-2 -mr-3 transition-colors duration-200"
              onClick={() => {
                setIsDrawerOpen(false);
                setSelectedContacts([]);
                setIsGroupNameInputVisible(false);
                setGroupName("");
                setGroupIcon(null);
              }}
            >
              &times;
            </button>
          </div>
          <ContactsList
            chatsUsers={allUsers}
            search={search}
            handleChatClick={handleChatClick}
            selectedChat={selectedChat}
            isGroupMode={true}
            handleSelectContact={handleSelectContact}
            selectedContacts={selectedContacts}
          />

          {selectedContacts.length > 0 && (
            <>
              <button
                className="absolute bottom-5 right-40 text-emerald-500 text-5xl rounded-full"
                onClick={() => setIsGroupNameInputVisible(true)}
              >
                <GoCheckCircleFill />
              </button>

              {isGroupNameInputVisible && (
                <div className="absolute bottom-20 right-6 bg-[#202d33] p-4 rounded-lg shadow-lg w-[350px]">
                  <div className="flex items-center mb-2 -mt-4 ml-8">
                    <div>
                      {groupIcon ? (
                        <img
                          src={groupIcon.preview}
                          alt="Group Icon"
                          className="rounded-full w-20 h-20 mx-auto cursor-pointer"
                          onClick={handleGroupIconClick}
                        />
                      ) : (
                        <IoPersonCircle
                          className="w-20 h-20 mx-auto cursor-pointer text-gray-600"
                          onClick={handleGroupIconClick}
                        />
                      )}
                    </div>
                    {groupIcon ? (
                      <button
                        className="text-gray-400"
                        onClick={handleRemoveIcon}
                      >
                        Remove group icon
                      </button>
                    ) : (
                      <div className="text-gray-400">Add group icon</div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <input
                    type="text"
                    placeholder="Enter group name"
                    className="w-full p-2 rounded-lg bg-[#111a21] text-white outline-none"
                    value={groupName}
                    onChange={handleGroupNameChange}
                  />
                  <button
                    className="mt-2 bg-emerald-500 w-full py-2 rounded-lg text-white"
                    onClick={handleGroupCreation}
                    disabled={groupName.trim() === ""}
                  >
                    Create Group
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>
      <Drawer
        open={isAddChatDrawerOpen}
        onClose={setIsAddChatDrawerOpen}
        direction="left"
        style={{
          width: "260px",
          backgroundColor: "RGB(34, 83, 87)",
        }}
      >
        <AllContact user={user} toggleDrawer={toggleAllUsersColumn} />
      </Drawer>
      ;
    </div>
  );
}

export default LeftMenu;
