import React, { useContext, useEffect, useState } from "react";
import SearchResults from "./SearchResults";
import { ConversationContext } from "../context/ConversationContext";

function AllContact({ user, toggleDrawer }) {
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [showSelection, setShowSelection] = useState(true);
  const { usersWithoutConversation } = useContext(ConversationContext);

  const sortedUsers = [...usersWithoutConversation].sort((a, b) => {
    const nameA = a.username ? a.username.toLowerCase() : "";
    const nameB = b.username ? b.username.toLowerCase() : "";
    return nameA.localeCompare(nameB);
  });

  return (
    <div className="flex h-screen">
      {showSelection && (
        <div
          className={`flex-1 h-full bg-[#111a21] ${
            showAllUsers ? "flex" : "hidden"
          }`}
        >
          <div className="bg-[#111a21] w-full flex-1 flex flex-col items-center max-h-screen">
            <SearchResults
              onClick={toggleDrawer}
              users={sortedUsers}
              user={user}
            />
          </div>
        </div>
      )}
      {!showSelection && (
        <div className="text-white">
          <p>No new users</p>
        </div>
      )}
    </div>
  );
}

export default AllContact;
