import CryptoJS from "crypto-js";
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// Encrypt a message
export const encryptMessage = (message) => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(
      message,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
    return ciphertext;
  } catch (error) {
    console.error("Error encrypting message:", error);
    return null;
  }
};

//Decrypt a message
export const decryptMessage = (encryptedMessage) => {
  try {
    if (encryptedMessage && encryptedMessage.includes("U2FsdGVkX1")) {
      const bytes = CryptoJS.AES.decrypt(
        encryptedMessage,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedMessage;
    } else {
      return encryptedMessage;
    }
  } catch (error) {
    console.error("Error decrypting message:", error);
    return null;
  }
};
