import React from 'react';
import defaultImage from "../assets/images/default.png";

function DefaultPage() {
  return (
    <div
      className={`bg-[#0a131a] bg-[url('assets/images/bg.webp')] bg-contain overflow-y-scroll h-100 scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800`}
      style={{ padding: '12px 7%' }}
    >
      <div className="flex flex-col h-screen relative justify-center items-center">
        <img className="w-96 max-h-96" src={defaultImage} alt="Default" />
        <p className="text-2xl text-white">Use Chat App in Your Computer</p>
        <p className="text-1xl text-white mb-4">Connect, Communicate, Collaborate</p>
        <p className="text-1xl text-white text-center">
          Welcome to ChatApp, the ultimate platform for seamless communication. Whether you're chatting with friends, collaborating with colleagues, or staying in touch with family, ChatApp offers a user-friendly interface and a host of features designed to enhance your messaging experience.
        </p>
      </div>
    </div>
  );
}

export default DefaultPage;