import React, { useState } from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";

const PollPopup = ({ onClose, onCreatePoll }) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [hoveredButton, setHoveredButton] = useState("");

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleSubmit = () => {
    if (question && options.every((option) => option.trim() !== "")) {
      onCreatePoll({ question, options });
      onClose();
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <div className="absolute top-0 left-0 w-full h-full bg-[#222f35] p-4 z-20 flex justify-center items-center">
      <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[400px] p-4 max-h-[500px] overflow-y-auto scrollbar scrollbar-thumb-gray-700 scrollbar-track-gray-400 relative">
        <h2 className="text-xl font-semibold mb-4">Create Poll</h2>
        <input
          type="text"
          placeholder="Enter your question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="w-full p-2 mb-4 bg-[#202d33] rounded-lg border border-gray-600 text-white"
        />
        <div className="space-y-2">
          {options.map((option, index) => (
            <div key={index} className="flex items-center">
              <input
                type="text"
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                className="w-full p-2 bg-[#202d33] rounded-lg border border-gray-600 text-white"
              />
              {options.length > 2 && (
                <button
                  onClick={() => handleRemoveOption(index)}
                  onMouseEnter={() => setHoveredButton("Remove")}
                  onMouseLeave={() => setHoveredButton("")}
                  className="ml-2 text-red-500 text-xl"
                >
                  <FaMinusCircle />
                  {hoveredButton === "Remove" && (
                    <span className="ml-2 text-sm">Remove</span>
                  )}
                </button>
              )}
            </div>
          ))}
        </div>
        <button
          onClick={handleAddOption}
          onMouseEnter={() => setHoveredButton("Add Option")}
          onMouseLeave={() => setHoveredButton("")}
          className="w-full p-2 mt-4 text-blue-500 text-xl flex justify-center items-center"
        >
          <FaPlusCircle />
          {hoveredButton === "Add Option" && (
            <span className="ml-2 text-sm">Add Option</span>
          )}
        </button>
        <button
          onClick={handleSubmit}
          onMouseEnter={() => setHoveredButton("Create Poll")}
          onMouseLeave={() => setHoveredButton("")}
          className="w-full p-2 mt-2 text-green-500 text-xl flex justify-center items-center"
        >
          <IoIosCreate className="text-xl" />
          {hoveredButton === "Create Poll" && (
            <span className="ml-2 text-sm">Create Poll</span>
          )}
        </button>
        <button
          onClick={onClose}
          onMouseEnter={() => setHoveredButton("Close")}
          onMouseLeave={() => setHoveredButton("")}
          className="absolute top-2 right-2 text-red-500 text-2xl"
        >
          <IoCloseCircle />
          {hoveredButton === "Close" && (
            <span className="ml-2 text-sm">Close</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default PollPopup;
