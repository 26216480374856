import { imgUrl } from "../utils/services";
import AllContacts from "./AllContacts";
import { IoMdClose } from "react-icons/io";
import { ConversationContext } from "../context/ConversationContext";
import { useContext } from "react";

function SearchResults({ users, onClick }) {
  const { addUserToConversation } = useContext(ConversationContext);

  return (
    <div className="flex flex-col h-full">
      <div className="bg-[#202d33] h-[60px] p-3 flex justify-between items-center w-[256px] z-10">
        <h2 className="text-white text-lg">Add to Chats</h2>
        <button
          className="text-[#fcfbfa] text-xl p-1 hover:bg-[#f60d01]"
          onClick={onClick}
        >
          <IoMdClose />
        </button>
      </div>
      <div className="flex flex-col p-2 w-[256px] justify-between items-center overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
        {users.length > 0 ? (
          users.map((user) => (
            <div
              key={user._id}
              onClick={() => addUserToConversation(user._id)}
              className="w-full flex justify-center"
            >
              <AllContacts
                pp={
                  `${imgUrl}${user.profilePicture}` ||
                  `https://avatar.iran.liara.run/username?username=${user.username}`
                }
                contact={user.username}
                phone={user.mobile}
              />
            </div>
          ))
        ) : (
          <p className="text-white">No users found</p>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
