import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from '../../context/AuthContext';
import { FaKey } from "react-icons/fa6";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import PasswordStrengthMeter from "../SignUpProfile/PasswordStrengthMeter.jsx";
import { GrLinkNext } from "react-icons/gr";
import { baseUrl } from "../../utils/services";

const ResetPassword = ({ onBackToLogin }) => {
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [timer, setTimer] = useState(null);
    const [resetSuccessful, setResetSuccessful] = useState(false);
    const [isStrongPassword, setIsStrongPassword] = useState(false);
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const { setIsAuthenticated, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        if (e.target.value) {
            setShowPasswordStrength(true);
        } else {
            setShowPasswordStrength(false);
        }
    };

    const handleRequestOtp = async () => {
        try {
            const response = await axios.post(`${baseUrl}/auth/forgotPassword`, { mobile });
            console.log("OTP request response:", response.data);
            setMessage(`OTP has been sent to your mobile ${mobile}.`);
            setMessage(response.data.message);
            setOtpSent(true);
            startTimer(600);
        } catch (error) {
            console.error("Error sending OTP:", error);
            setMessage(error.response ? error.response.data.message : 'Error sending OTP');
        }
    };

    const handleResetPassword = async () => {
        console.log("Resetting password with OTP:", otp, "and new password:", newPassword);

        if (newPassword !== confirmNewPassword) {
            setMessage('Passwords do not match.');
            setError(true);
            return;
        }

        if (!isStrongPassword) {
            setMessage('Password is not strong enough.');
            setError(true);
            return;
        }

        try {
            const response = await axios.post(`${baseUrl}/auth/resetPassword`, { mobile, otp, newPassword });
            console.log("Password reset response:", response.data);
            setMessage(response.data.message);
            setResetSuccessful(true);

        } catch (error) {
            console.error("Error resetting password:", error);
            setMessage(error.response ? error.response.data.message : 'Error resetting password');
            setResetSuccessful(false);
        }
    };

    const startTimer = (seconds) => {
        console.log('Starting OTP expiration timer for:', seconds, 'seconds');
        let timeLeft = seconds;
        const interval = setInterval(() => {
            timeLeft--;
            setTimer(timeLeft);
            if (timeLeft <= 0) {
                clearInterval(interval);
                setOtpSent(false);
                setMessage('OTP expired. Please request a new one.');
                console.log('OTP expired');
                setError(true);
            }
        }, 1000);
    };

    const handleNavigateToChatweb = () => {
        navigate('/chatweb');
    };
    const togglePasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmNewPasswordVisibility = () => {
        setShowNewConfirmPassword(!showNewConfirmPassword);
    };

    return (
        <div className="flex justify-center items-center min-h-screen align-center">
            <div className="relative bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 bg-opacity-50 backdrop-blur-md p-12 rounded-lg shadow-lg max-w-xs w-full sm:max-w-md lg:max-w-lg">
                <div className="absolute inset-0 bg-gray-800 bg-opacity-40 rounded-lg"></div>

                <div className="relative z-10">
                    <h2 className="text-3xl font-bold mb-6 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text">
                        Reset Password
                    </h2>

                    <div className="relative mb-4">
                        <input
                            type="text"
                            placeholder="Enter before used mobile number"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            required
                            className="w-full p-6 max-w-lg placeholder:text-sm bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                        />
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                            <FaPhone size={20} />
                        </span>
                    </div>

                    {otpSent && (
                        <>
                            <div className="relative mb-4">
                                <input
                                    type="text"
                                    placeholder="Enter the OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    required
                                    className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                                />
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                    <FaKey size={20} />
                                </span>
                            </div>

                            <div className="relative mb-4">
                                <input
                                    type={showNewPassword ? "text" : "password"}
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                    onFocus={() => setShowPasswordStrength(true)}
                                    onBlur={() => setShowPasswordStrength(newPassword.length > 0)}
                                    required
                                    className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                                />
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                    <FaLock size={20} />
                                </span>
                                <span className="absolute inset-y-0 right-0 flex pt-4 pr-6 cursor-pointer" onClick={togglePasswordVisibility}>
                                    {showNewPassword ? <FaEye size={20} color="white" /> : <FaEyeSlash size={20} color="white" />}
                                </span>
                            </div>
                            {showPasswordStrength && <PasswordStrengthMeter password={newPassword} setIsStrongPassword={setIsStrongPassword} />}

                            <div className="relative mb-4">
                                <input
                                    type={showNewConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm your password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    required
                                    className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                                />
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                    <FaLock size={20} />
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-6 cursor-pointer" onClick={toggleConfirmNewPasswordVisibility}>
                                    {showNewConfirmPassword ? <FaEye size={20} color="white" /> : <FaEyeSlash size={20} color="white" />}
                                </span>
                            </div>

                            {message && (
                                <p className={`text-center mt-4 ${resetSuccessful ? 'text-green-300' : 'text-red-300'}`}>
                                    {message} {timer > 0 && `OTP Expires in ${Math.floor(timer / 60)}:${timer % 60}`}
                                </p>
                            )}

                            {!resetSuccessful ? (
                                <button onClick={handleResetPassword}
                                    className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white py-3 rounded-lg font-bold hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200">
                                    Reset Password
                                </button>
                            ) : (
                                <button
                                    onClick={handleNavigateToChatweb}
                                    className="flex items-center justify-center bg-green-600 p-3 rounded-full text-center mx-auto mt-4 w-fit" >
                                    <GrLinkNext className="text-white" size={20} />
                                </button>
                            )}
                        </>
                    )}

                    {!otpSent && (
                        <button onClick={handleRequestOtp}
                            className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white py-3 rounded-lg font-bold hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200">
                            Request OTP
                        </button>
                    )}

                    <button onClick={onBackToLogin} className="mt-4 text-green-400 hover:underline text-left block w-full">
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
