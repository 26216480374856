import React, { useEffect, useState, useContext } from "react";
import { AiOutlineClose, AiOutlineBlock, AiOutlineFlag, AiOutlineDelete } from "react-icons/ai";
import {MdModeEdit,MdNotificationsOff, MdOutlineMessage, MdOutlineLock,} from "react-icons/md";
import { MdPeopleAlt } from "react-icons/md";
import { AuthContext } from "../context/AuthContext";
import { imgUrl, baseUrl } from "../utils/services";
import AddMembers from "./AddMembers";
import ParticipantsList from "./ParticipantsList";
import ProfileView from "./ProfileView";
import axios from 'axios';
import Media from "./Media";

const UserDetails = ({ chat, mediaImages, onClose }) => {
  const [showMedia, setShowMedia] = useState(false);
  const [participants, setParticipants] = useState(chat?.participants || []);
  const [groupAdmins, setGroupAdmins] = useState(chat?.groupAdmins || []);
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [chatIcon, setChatIcon] = useState(chat.chatIcon);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setParticipants(chat?.participants || []); 
    setGroupAdmins(chat?.groupAdmins || []);
    setChatIcon(chat?.chatIcon || "");
  }, [chat]);

  const handleAddMembers = async (newMembers) => {
    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        `${baseUrl}/conversations/addMembers`,
        {
          conversationId: chat._id,
          newMembers,
          requesterId: user._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setParticipants(response.data.participants);
      } else {
        throw new Error("Failed to add members");
      }
    } catch (error) {
      console.error("Error adding members:", error);
    }
  };

  const handleShowAddMembers = () => {
    setShowAddMembers(true);
  };

  const handleCloseAddMembers = () => {
    setShowAddMembers(false);
  };

  const handleBlockContact = () => {
    console.log("Block Contact clicked");
  };

  const handleReportContact = () => {
    console.log("Report Contact clicked");
  };

  const handleDeleteChat = () => {
    console.log("Delete Chat clicked");
  };

  const handleMuteNotifications = () => {
    console.log("Mute Notifications clicked");
  };

  const handleDisappearingMessages = () => {
    console.log("Disappearing Messages clicked");
  };

  const handleEncryption = () => {
    console.log("Encryption clicked");
  };

  const handleShowMedia = () => {
    setShowMedia(true);
  };

  const handleCloseMedia = () => {
    setShowMedia(false);
  };

  const promoteToAdmin = async (userId) => {
    if (!groupAdmins.includes(user._id)) {
      console.error("You do not have permission to promote users.");
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${baseUrl}/conversations/assignAdmins`,
        {
          conversationId: chat._id,
          adminIds: [userId],
          requesterId: user._id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGroupAdmins((prev) => [...prev, userId]);
      console.log("User promoted to admin successfully:", response.data);
    } catch (error) {
      console.error("Error promoting user:", error.response?.data || error.message);
    }
  };

  const removeUser = async (userId) => {
    if (!groupAdmins.includes(user._id)) {
      console.error("Unauthorized: You do not have permission to remove users.");
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${baseUrl}/conversations/removeMembers`,
        {
          conversationId: chat._id,
          membersToRemove: [userId],
          requesterId: user._id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setParticipants((prevParticipants) =>
        prevParticipants.filter((participant) => participant._id !== userId)
      );

      setGroupAdmins((prevAdmins) =>
        prevAdmins.filter((adminId) => adminId !== userId)
      );

      console.log("User removed from group successfully:", response.data);
    } catch (error) {
      console.error("Error removing user:", error.response?.data || error.message);
    }
  };

  const handleExitGroup = async () => {
    try {
      const token = localStorage.getItem('token');
  
      let isLastAdmin = groupAdmins.length === 1 && groupAdmins.includes(user._id);
  
      let newAdminId = null;
      if (isLastAdmin && participants.length > 1) {
        const remainingParticipants = participants.filter(participant => participant._id !== user._id);
  
        if (remainingParticipants.length > 0) {
          
          const randomIndex = Math.floor(Math.random() * remainingParticipants.length);
          newAdminId = remainingParticipants[randomIndex]._id;
  
          setGroupAdmins([newAdminId]);
        }
      }
      const response = await axios.post(
        `${baseUrl}/conversations/leave`,
        {
          conversationId: chat._id,
          userId: user._id, 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("User exited the group successfully:", response.data);
      if (newAdminId) {
        await axios.post(
          `${baseUrl}/conversations/assignAdmins`,
          {
            conversationId: chat._id,
            adminIds: [newAdminId],
            requesterId: user._id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(`New admin assigned: ${newAdminId}`);
      }
  
      setParticipants((prevParticipants) =>
        prevParticipants.filter((participant) => participant._id !== user._id)
      );
      onClose(); 
    } catch (error) {
      console.error("Error exiting group:", error.response?.data || error.message);
    }
  };


  return (
    <div className="flex flex-col h-full p-4 bg-[#202d33] text-white">
      {showMedia ? (
        <Media images={mediaImages} onBack={handleCloseMedia} />
      ) : (
        <>
          <button
            className="text-white mb-4 self-start text-xl"
            onClick={onClose}
          >
            <AiOutlineClose />
          </button>
          <div className="flex-1 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800 space-y-4">

            {chat.isGroupChat ? (
              <>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <div className="flex flex-col items-center mb-4">
                    <ProfileView
                      chat={chat}
                      user={user}
                      groupAdmins={groupAdmins}
                      setChatIcon={setChatIcon}
                      baseUrl={baseUrl}
                    />
                  </div>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <span className="flex gap-3 text-slate-300 text-xl">
                    <h2 className="text-sm text-green-400 mb-2">Add Group Description</h2>
                    <MdModeEdit />
                  </span>
                  <p className="text-sm text-gray-300">{chat.user}</p>
                  <p className="text-sm text-gray-300">{chat.about}</p>
                </div>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  {groupAdmins.includes(user._id) && (
                    <button className="mt-2 text-gray-100 flex items-center" onClick={handleShowAddMembers}>
                      <div className="bg-green-500 rounded-full p-3 flex items-center justify-center">
                        <MdPeopleAlt className="text-white text-2xl" />
                      </div>
                      <span className="ml-2">Add Members</span>
                    </button>
                  )}

                  {showAddMembers && (
                    <AddMembers
                      currentMembers={participants}
                      onClose={handleCloseAddMembers}
                      onAddMembers={handleAddMembers}
                      chatId={chat._id}
                    />
                  )}
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">Participants</h2>
                  <ParticipantsList
                    participants={participants}
                    groupAdmins={groupAdmins}
                    onPromote={promoteToAdmin}
                    onRemove={removeUser}
                    currentUserId={user._id}
                  />
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2 cursor-pointer" onClick={handleShowMedia}>
                    Media
                  </h2>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2 cursor-pointer text-red-500" onClick={handleExitGroup}>
                    Exit Group
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <div className="flex flex-col items-center mb-4">
                    <img
                      src={`${imgUrl}${chat?.chatIcon}`}
                      alt="profile_picture"
                      className="rounded-full w-[100px] h-[100px] mb-4"
                    />
                    <h1 className="text-2xl font-medium mb-2">{chat.chatName}</h1>
                    <p className="text-sm text-gray-400 mb-2">
                      {chat.mobile}
                    </p>
                  </div>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">Options</h2>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleBlockContact}>
                      <AiOutlineBlock className="mr-2" />
                      <span>Block Contact</span>
                    </div>
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleReportContact}>
                      <AiOutlineFlag className="mr-2" />
                      <span>Report Contact</span>
                    </div>
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleDeleteChat}>
                      <AiOutlineDelete className="mr-2" />
                      <span>Delete Chat</span>
                    </div>
                  </div>
                </div>

                <div className="bg-[#1c2730] p-4 rounded-lg">
                  <h2 className="text-xl font-semibold mb-2">More Options</h2>
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleMuteNotifications}>
                      <MdNotificationsOff className="mr-2" />
                      <span>Mute Notifications</span>
                    </div>
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleDisappearingMessages}>
                      <MdOutlineMessage className="mr-2" />
                      <span>Disappearing Messages</span>
                    </div>
                    <div className="flex items-center text-red-500 cursor-pointer" onClick={handleEncryption}>
                      <MdOutlineLock className="mr-2" />
                      <span>Encryption</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
