import { Check, X } from "lucide-react";
import React, { useState, useEffect } from "react";

const PasswordCriteria = ({ password }) => {
    const criteria = [
        { label: "At least 6 characters", met: password.length >= 6 },
        { label: "Contains uppercase letter", met: /[A-Z]/.test(password) },
        { label: "Contains lowercase letter", met: /[a-z]/.test(password) },
        { label: "Contains a number", met: /\d/.test(password) },
    ];

    return (
        <div className='mt-2 space-y-1'>
            {criteria.map((item) => (
                <div key={item.label} className='flex items-center text-xs'>
                    {item.met ? (
                        <Check className='size-4 text-green-500 mr-2' />
                    ) : (
                        <X className='size-4 text-gray-500 mr-2' />
                    )}
                    <span className={item.met ? "text-green-500" : "text-gray-400"}>{item.label}</span>
                </div>
            ))}
        </div>
    );
};

const PasswordStrengthMeter = ({ password, setIsStrongPassword }) => {
    const [hideMeter, setHideMeter] = useState(false);

    const getStrength = (pass) => {
        let strength = 0;
        if (pass.length >= 6) strength++;
        if (pass.match(/[a-z]/) && pass.match(/[A-Z]/)) strength++;
        if (pass.match(/\d/)) strength++;
        return strength;
    };
    const strength = getStrength(password);

    useEffect(() => {
        if (strength >= 3) {
            setIsStrongPassword(true);
            const timer = setTimeout(() => {
                setHideMeter(true);
            }, 2000);

            return () => clearTimeout(timer);

        } else {
            setIsStrongPassword(false);
            setHideMeter(false);
        }
    }, [strength]);

    if (hideMeter) {
        return null;
    }

    const getColor = (strength) => {
        if (strength === 0) return "bg-red-500";
        if (strength === 1) return "bg-red-400";
        if (strength === 2) return "bg-yellow-500";
        return "bg-green-500";
    };

    const getStrengthText = (strength) => {
        if (strength === 0) return "Very Weak";
        if (strength === 1) return "Weak";
        if (strength === 2) return "Good";
        return "Strong";
    };

    return (
        <div className='mt-2 pb-3'>
            <div className='flex justify-between items-center mb-1'>
                <span className='text-xs text-gray-400'>Password strength</span>
                <span className='text-xs text-gray-400'>{getStrengthText(strength)}</span>
            </div>

            <div className='flex space-x-2 justify-between'>
                {[...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className={`h-1 w-1/3 rounded-full transition-colors duration-300 
                ${index < strength ? getColor(strength) : "bg-gray-600"}
              `}
                    />
                ))}
            </div>
            <PasswordCriteria password={password} />
        </div>
    );
};
export default PasswordStrengthMeter;