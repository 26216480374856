import React, { useState, useRef, useEffect } from "react";
import { AiOutlinePaperClip } from "react-icons/ai";
import { BiHappy } from "react-icons/bi";
import { BsFillMicFill } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import Button from "./Common/RoundedBtn";
import {
  FaPaperclip,
  FaMicrophone,
  FaSmile,
  FaTrash,
  FaPauseCircle,
  FaPlay,
} from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";

import ReplyManager from "./ReplyManager";

function ChatInput({
  onEmojiClick,
  onEmojiSelect,
  onAttachmentToggle,
  onInputSubmit,
  onVoiceMessageSubmit,
  typing,
  inputRef,
  replyingTo,
  onSubmit,
}) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [hasText, setHasText] = useState(false);
  const [isAttachmentActive, setIsAttachmentActive] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [waveform, setWaveform] = useState(null);
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0);
  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const canvasRef = useRef(null);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    if (chunks.length > 0) {
      const audioBlob = new Blob(chunks, { type: "audio/wav" });
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
      audioRef.current.src = url;
      audioRef.current.addEventListener("ended", handleAudioEnd);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, [chunks]);

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (audioUrl) {
      audioRef.current.src = audioUrl;
      audioRef.current.addEventListener("ended", handleAudioEnd);
    }
    return () => {
      audioRef.current.removeEventListener("ended", handleAudioEnd);
    };
  }, [audioUrl]);

  useEffect(() => {
    let timer;
    if (isRecording && !isPaused) {
      timer = setInterval(() => {
        setRecordingTime((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRecording, isPaused]);

  const handleEmojiClick = () => {
    setShowEmojiPicker(!showEmojiPicker);
    setHasText(true);
    onEmojiClick();
  };

  const handleEmojiSelect = (emojiData) => {
    if (inputRef.current) {
      inputRef.current.value += emojiData.emoji;
      inputRef.current.focus();
      setHasText(inputRef.current.value.length > 0);
    }
  };

  const toggleAttachmentDialog = () => {
    setIsAttachmentActive((prev) => !prev);
    setHasText(true);
    onAttachmentToggle();
  };

  const handleInputChange = () => {
    if (inputRef.current) {
      const inputHasText = inputRef.current.value.length > 0;
      setHasText(inputHasText);
      typing(inputHasText);
    }
  };

  const handleTextInputSubmit = () => {
    if (inputRef.current && inputRef.current.value) {
      const messageContent = inputRef.current.value;
      const referenceMessage = replyingTo
        ? `Replying to: ${replyingTo.msg}\n`
        : "";

      const message = {
        content: referenceMessage + messageContent,
        replyTo: replyingTo ? { id: replyingTo.id, msg: replyingTo.msg } : null,
      };

      onInputSubmit(message);
      inputRef.current.value = "";
      setHasText(false);
      setShowEmojiPicker(false);
      setIsAttachmentActive(false);
      deleteRecording();
    } else {
      console.error("Input cannot be empty");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleTextInputSubmit();
    }
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);

          const audioCtx = new (window.AudioContext ||
            window.webkitURL.AudioContext)();
          const analyserNode = audioCtx.createAnalyser();
          analyserNode.fftSize = 2048;
          const source = audioCtx.createMediaStreamSource(stream);
          source.connect(analyserNode);

          setAudioContext(audioCtx);
          setAnalyser(analyserNode);

          recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              const audioBlob = new Blob([e.data], { type: "audio/wav" });

              setChunks((prev) => [...prev, audioBlob]);
              onVoiceMessageSubmit(audioBlob);
            }
          };
          recorder.start();
          setIsRecording(true);
          setIsPaused(false);
          setCurrentPlaybackTime(0);
          setRecordingTime(0);
          visualizeWaveform(analyserNode);
        })
        .catch((err) => console.error("Error accessing audio devices", err));
    }
  };

  const visualizeWaveform = (analyserNode) => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    let offset = 0;

    const drawWaveform = () => {
      const bufferLength = analyserNode.fftSize;
      const dataArray = new Uint8Array(bufferLength);

      analyserNode.getByteTimeDomainData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      canvasCtx.fillStyle = "#202d33";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      const midY = canvas.height / 2;

      if (isPaused && isRecording) {
        canvasCtx.strokeStyle = "#4caf50";
        canvasCtx.lineWidth = 2;
        canvasCtx.beginPath();
        canvasCtx.moveTo(0, midY);
        canvasCtx.lineTo(canvas.width, midY);
        canvasCtx.stroke();
      } else {
        canvasCtx.lineWidth = 2;
        canvasCtx.strokeStyle = "#4caf50";
        canvasCtx.beginPath();

        const sliceWidth = (canvas.width * 1.0) / bufferLength;
        let x = canvas.width;

        const addMovement = (v, i) => {
          if (v < 0.1 && v > -0.1) {
            return v + (Math.random() * 0.05 - 0.025);
          }
          return v;
        };

        for (let i = 0; i < bufferLength; i++) {
          let v = dataArray[i] / 128.0 - 1;
          v = addMovement(v, i);
          const y = (v * canvas.height) / 2 + canvas.height / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }

          x -= sliceWidth;
        }

        canvasCtx.lineTo(0, canvas.height / 2);
        canvasCtx.stroke();
      }

      offset += 2;
      if (offset > canvas.width) {
        offset = 0;
      }

      if (isRecording) {
        requestAnimationFrame(drawWaveform);
      }
    };

    drawWaveform();
  };

  useEffect(() => {
    console.log("Replying To in ChatInput:", replyingTo);
  }, [replyingTo]);

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      if (audioContext) {
        audioContext.close();
        setAudioContext(null);
        setAnalyser(null);
      }
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.resume();
      setIsPaused(false);
    }
  };

  const playRecording = () => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const pausePlayback = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlayback = () => {
    if (isPlaying) {
      pausePlayback();
    } else {
      playRecording();
    }
  };

  const deleteRecording = () => {
    setChunks([]);
    setIsRecording(false);
    setRecordingTime(0);
  };

  const formatTime = (time) => {
    if (isNaN(time)) return "00:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-col bg-[#202d33] w-full">
      {replyingTo && (
        <ReplyManager
          replyingTo={replyingTo}
          onCancelReply={() => onSubmit(null)}
        />
      )}

      <div className="flex items-center w-full h-[70px] p-2">
        {!isRecording && (
          <>
            <Button icon={<BiHappy />} onClick={handleEmojiClick} />
            <span className="mr-2">
              <Button
                icon={<AiOutlinePaperClip />}
                onClick={toggleAttachmentDialog}
              />
            </span>
            <input
              type="text"
              placeholder="Type a message"
              className="bg-[#2c3943] rounded-lg outline-none text-sm text-neutral-200 w-[1050%] h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
              onChange={handleInputChange}
              ref={inputRef}
              onKeyDown={handleKeyDown}
            />
          </>
        )}

        {isRecording && (
          <>
            <Button
              icon={<FaTrash />}
              onClick={deleteRecording}
              className="bg-gray-700 hover:bg-gray-600 rounded-full ml-20 text-gray-300"
            />

            {isPaused && (
              <>
                <Button
                  icon={isPlaying ? <FaPauseCircle /> : <FaPlay />}
                  onClick={togglePlayback}
                  className="bg-gray-700 hover:bg-gray-600 rounded-full ml-2 text-gray-300"
                />
              </>
            )}

            <canvas
              ref={canvasRef}
              width={200}
              height={40}
              className="bg-[#2a3137] rounded-lg mr-8 ml-2"
            />
          </>
        )}

        {isRecording && (
          <div className="flex items-center mr-8 ml-8">
            <span className="text-white">{formatTime(recordingTime)}</span>
            <Button
              icon={
                isPaused ? (
                  <FaMicrophone className="text-red-600" />
                ) : (
                  <FaPauseCircle className="text-red-600" />
                )
              }
              onClick={isPaused ? resumeRecording : pauseRecording}
              className="bg-gray-700 hover:bg-gray-600 rounded-full ml-2 text-gray-300"
            />
          </div>
        )}

        <span className="w-full flex items-center justify-end mr-2">
          {hasText || showEmojiPicker || isAttachmentActive ? (
            <Button icon={<MdSend />} onClick={handleTextInputSubmit} />
          ) : (
            <Button
              icon={isRecording ? <MdSend /> : <BsFillMicFill />}
              onClick={isRecording ? stopRecording : startRecording}
              className={`p-2 rounded-full text-white ${
                isRecording
                  ? "bg-green-500 hover:bg-green-700"
                  : "bg-[#2a3137] hover:bg-[#3e4e5a]"
              }`}
            />
          )}
        </span>

        {showEmojiPicker && (
          <div className="absolute bottom-[70px] left-0 z-50">
            <EmojiPicker onEmojiClick={handleEmojiSelect} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatInput;
