import React from "react";

function DeleteConfirmationDialog({ onConfirm, onCancel, isSent }) {
  return (
    <div
      className="absolute z-50 top-0 right-0 mt-2 mr-2 border rounded-md shadow-lg"
      style={{ backgroundColor: "#1c4f3f" }}
    >
      <div className="p-2 text-white">
        {isSent ? (
          <>
            <button
              onClick={() => onConfirm("me")}
              className="block px-4 py-2 hover:bg-[#14543e] w-full text-left"
            >
              Delete for Me
            </button>
            <button
              onClick={() => onConfirm("everyone")}
              className="block px-4 py-2 hover:bg-[#14543e] w-full text-left"
            >
              Delete for Everyone
            </button>
            <button
              onClick={onCancel}
              className="block px-4 py-2 hover:bg-[#14543e] w-full text-left"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => onConfirm("me")}
              className="block px-4 py-2 hover:bg-[#14543e] w-full text-left"
            >
              Delete for Me
            </button>
            <button
              onClick={onCancel}
              className="block px-4 py-2 hover:bg-[#14543e] w-full text-left"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default DeleteConfirmationDialog;
