import React, { createContext, useEffect, useState ,useContext} from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log("Retrieved token:", token);
    if (token) {
      axios
        .post(
          "https://developer.1xstarz.com/api/auth/check",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setIsAuthenticated(true);
          setUser(response.data.user);
        })
        .catch((response) => {
          console.log("Server response:", response.data);
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          setUser(null);
        });
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, setIsAuthenticated, setUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};


export const useAuthContext = () => useContext(AuthContext)