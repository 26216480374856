import React, { useContext, useEffect, useRef, useState } from "react";
import Message from "./MessageBuble";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import SearchPopup from "./SearchPopup";
import AttachmentDialog from "./AttachmentDialog";
import Contacts from "./ContactShareDialog";
import PinnedMessageDialog from "./PinnedMessageDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import { getTime } from "../logic/chatweb";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../context/AuthContext";
// import { io } from "socket.io-client";
import { useSocketContext } from "../context/SocketContext";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  formatDateTime,
} from "../utils/services";
import { encryptMessage, decryptMessage } from "../utils/MessageEncryptDecrypt";
// const socket = io("https://developer.1xstarz.com");

function ChatDetail({
  onProfileClick,
  selectedChat,
  updateChatList,
  addNewChat,
  isMobile,
  onBackClick,
}) {
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [showProfilePicDialog, setShowProfilePicDialog] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [showContactsList, setShowContactsList] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [pinnedMessages, setPinnedMessages] = useState([]);
  const [replyingTo, setReplyingTo] = useState(null);
  const [currentPinnedIndex, setCurrentPinnedIndex] = useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const inputRef = useRef(null);
  const bottomRef = useRef(null);
  const chatDetailsRef = useRef(null);
  const { user } = useContext(AuthContext);
  const { socket } = useSocketContext();

  const fetchMessages = async () => {
    const chatId = selectedChat._id;
    if (!chatId) {
      console.error("Selected chat ID is undefined");
      return;
    }

    const response = await getRequest(`/messages/${chatId}`);
    if (!response.error) {
      const fetchedMessages = response.map((message) => ({
        _id: message._id,
        msg: decryptMessage(message.message),
        time: formatDateTime(message.createdAt),
        sent: message.senderId === user._id,
        senderId: message.senderId,
        senderProfilePicture: message.senderProfilePicture,
        createdAt: message.createdAt,
        isPinned: message.isPinned,
        reactions: message.reactions,
        files: message.files,
        isForwarded: message.isForwarded,
      }));

      setMessages(fetchedMessages);
      setPinnedMessages(fetchedMessages.filter((msg) => msg.isPinned));
    } else {
      console.error("Error fetching messages:", response.message);
    }
  };

  useEffect(() => {
    // if (selectedChat?._id) {
    if (socket && selectedChat?._id) {
      fetchMessages();

      socket.emit("joinRoom", selectedChat._id);

      socket.on("newMessage", (newMessage) => {
        if (newMessage.chatId === selectedChat._id) {
          setMessages((prevMessages) => {
            if (prevMessages.some((msg) => msg._id === newMessage._id)) {
              return prevMessages;
            }

            updateChatList();

            return [
              ...prevMessages,
              {
                _id: newMessage._id,
                msg: decryptMessage(newMessage.message),
                time: getTime(newMessage.createdAt),
                sent: newMessage.senderId === user._id,
                senderId: newMessage.senderId,
                senderProfilePicture: newMessage.senderProfilePicture,
                isPinned: newMessage.isPinned,
                files: newMessage.files,
              },
            ];
          });
        }
        addNewChat();
      });

      // Listen for message edit events
      socket.on("messageEdited", (editedMessage) => {
        if (editedMessage.chatId === selectedChat._id) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg._id === editedMessage.messageId
                ? {
                    ...msg,
                    msg: decryptMessage(editedMessage.newMessageContent),
                    editedAt: editedMessage.editedAt,
                  }
                : msg
            )
          );
        }
      });

      // Listen for message deletion events
      socket.on("messageDeleted", ({ messageId, conversationId }) => {
        if (conversationId === selectedChat._id) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg._id === messageId
                ? {
                    ...msg,
                    msg: "This message was deleted ",
                    isDeleted: true,
                    file: null,
                    audio: null,
                    img: null,
                  }
                : msg
            )
          );
        }
      });

      // Remove a message for a specific user only
      socket.on("messageDeletedForMe", ({ messageId, chatId }) => {
        if (chatId === selectedChat._id) {
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg._id !== messageId)
          );
        }
      });

      // Clean up listeners when the component unmounts or when the chat changes
      return () => {
        socket.emit("leaveRoom", selectedChat._id);
        socket.off("newMessage");
        socket.off("messageEdited");
        socket.off("messageDeleted");
        socket.off("messageDeletedForMe");
      };
    }
  }, [selectedChat, updateChatList, user._id]);

  // Handle message deletion
  const handleDeleteMessage = async (index, option) => {
    const messageToDelete = messages[index];
    const chatId = selectedChat._id;
    const messageId = messageToDelete._id;

    let response;

    if (option === "everyone") {
      response = await deleteRequest(
        `/messages/conversation/${chatId}/message/${messageId}/for-everyone`
      );
    } else if (option === "me") {
      response = await deleteRequest(`/messages/message/${messageId}/for-me`);
    } else {
      response = await deleteRequest(
        `/messages/conversation/${chatId}/message/${messageId}`
      );
    }

    if (!response.error) {
      if (option === "everyone") {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[index] = {
            ...updatedMessages[index],
            msg: response.message,
            isDeleted: true,
            file: null,
            audio: null,
            img: null,
          };
          return updatedMessages;
        });
      } else if (option === "me") {
        setMessages((prevMessages) =>
          prevMessages.filter((_, msgIndex) => msgIndex !== index)
        );
      } else {
        setMessages((prevMessages) =>
          prevMessages.filter((_, msgIndex) => msgIndex !== index)
        );
      }
    } else {
      console.error("Error deleting message:", response.message);
    }
  };

  const updateMessage = async (index, newMessageContent) => {
    const messageToUpdate = messages[index];
    const chatId = selectedChat._id;
    const messageId = messageToUpdate._id;

    const response = await putRequest(
      `/messages/conversation/${chatId}/message/${messageId}`,
      { newMessageContent }
    );

    if (!response.error) {
      const setupdatedMessage = decryptMessage(newMessageContent);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index] = {
          ...updatedMessages[index],
          msg: setupdatedMessage,
          editedAt: response.updatedMessage.editedAt,
        };
        return updatedMessages;
      });
    } else {
      console.error("Error updating message:", response.message);
    }
  };

  const handleInputSubmit = async () => {
    if (inputRef.current.value.length > 0) {
      const chatId = selectedChat._id;
      if (!chatId) {
        console.error("Selected chat ID is undefined");
        return;
      }

      const encryptedMessage = encryptMessage(inputRef.current.value);

      const newMessage = {
        message: encryptedMessage,
      };
      const response = await postRequest(
        `/messages/send/${chatId}`,
        newMessage
      );
      if (!response.error) {
        inputRef.current.value = "";
        inputRef.current.focus();
        setTyping(false);
      } else {
        console.error("Error sending message:", response.message);
      }
    }
  };

  // Add Message function
  const addMessage = (msg) => {
    setMessages((prevMessages) => [...prevMessages, msg]);
  };

  // Pin a message
  const handlePinMessage = async (index, duration) => {
    const messageToPin = messages[index];
    const chatId = selectedChat._id;

    const response = await postRequest(`/messages/pinMessage/${chatId}`, {
      messageId: messageToPin._id,
      duration,
    });

    if (!response.error) {
      setMessages((prevMessages) =>
        prevMessages.map((msg, i) =>
          i === index ? { ...msg, isPinned: true } : msg
        )
      );
      setPinnedMessages((prevPinnedMessages) => [
        ...prevPinnedMessages,
        messageToPin,
      ]);
    } else {
      console.error("Error pinning message:", response.message);
    }
  };

  const handleUnpinMessage = async (messageId) => {
    const messageToUnpin = messages.find((msg) => msg._id === messageId);

    if (!messageToUnpin || !messageToUnpin._id) {
      console.error("Message or message ID is missing", messageToUnpin);
      return;
    }

    const chatId = selectedChat._id;

    try {
      const response = await postRequest(`/messages/unpinMessage/${chatId}`, {
        messageId: messageToUnpin._id,
      });

      if (!response.error) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === messageId ? { ...msg, isPinned: false } : msg
          )
        );
        setPinnedMessages((prevPinnedMessages) =>
          prevPinnedMessages.filter((msg) => msg._id !== messageId)
        );
      } else {
        console.error("Error unpinning message:", response.message);
      }
    } catch (error) {
      console.error("Failed to unpin message:", error);
    }
  };

  const handleReply = (msg) => {
    setReplyingTo(msg);
  };

  const handleNextPinnedMessage = () => {
    setCurrentPinnedIndex(
      (prevIndex) => (prevIndex + 1) % pinnedMessages.length
    );
  };

  const handlePrevPinnedMessage = () => {
    setCurrentPinnedIndex(
      (prevIndex) =>
        (prevIndex - 1 + pinnedMessages.length) % pinnedMessages.length
    );
  };

  const handleClearChat = () => {
    setShowConfirmationDialog(true);
    setActionType("clearChat");
  };

  const handleBlockContact = () => {
    setShowConfirmationDialog(true);
    setActionType("block");
  };

  const handleUnblockContact = () => {
    setShowConfirmationDialog(true);
    setActionType("unblock");
  };

  const confirmAction = async () => {
    if (actionType === "block") {
      try {
        const response = await postRequest("/conversations/block", {
          conversationId: selectedChat._id,
          userToBlockId: selectedChat.participants.find(
            (participantId) => participantId !== user._id
          ),
        });
        if (!response.error) {
          setIsBlocked(true);
          console.log("Contact blocked successfully.");
        }
      } catch (error) {
        console.error("Error blocking contact:", error.message);
      }
    } else if (actionType === "unblock") {
      try {
        const response = await postRequest("/conversations/unblock", {
          conversationId: selectedChat._id,
          userToUnblockId: selectedChat.participants.find(
            (participantId) => participantId !== user._id
          ),
        });
        if (!response.error) {
          setIsBlocked(false);
          console.log("Contact unblocked successfully.");
        }
      } catch (error) {
        console.error("Error unblocking contact: ", error.message);
      }
    } else if (actionType === "clearChat") {
      try {
        const response = await deleteRequest(
          `/messages/clear/${selectedChat._id}`,
          {}
        );

        if (response.success) {
          console.log("Messages cleared for the user.");
          // Optionally update the UI to reflect the cleared messages
          setMessages((prevMessages) =>
            prevMessages.filter(
              (msg) => msg.clearedFor && !msg.clearedFor.includes(user._id)
            )
          );
        } else {
          console.error("Failed to clear messages:", response.error);
        }
      } catch (error) {
        console.error("Error clearing chat:", error.message);
      }
    }
    setShowConfirmationDialog(false);
  };

  const cancelAction = () => {
    setShowConfirmationDialog(false);
  };

  const handleFileSelect = (files) => {
    files.forEach((file) => {
      const isImage = file.type.startsWith("image/");
      addMessage({
        msg: isImage ? null : file.name,
        time: getTime(),
        sent: true,
        file: file,
        isImage: isImage,
      });
    });
  };

  const handleSendContacts = (contacts) => {
    contacts.forEach((contact) => {
      addMessage({
        id: uuidv4(),
        msg: `Shared contact: ${contact}`,
        time: getTime(),
        sent: true,
      });
    });
    setSelectedContacts(contacts);
  };

  const handleSendPoll = (poll) => {
    addMessage({
      poll: poll,
      time: getTime(),
      sent: true,
    });
  };

  const handleVoiceMessageSubmit = (audioBlob) => {
    const audioURL = URL.createObjectURL(audioBlob);
    addMessage({
      id: uuidv4(),
      audio: audioURL,
      time: getTime(),
      sent: true,
    });
  };

  const handleEmojiSelect = (emoji) => {
    if (inputRef.current) {
      inputRef.current.value += emoji;
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [messages]);

  useEffect(() => {
    if (selectedChat?.blockedBy?.some((block) => block.userId === user?._id)) {
      setIsBlocked(true);
    } else {
      setIsBlocked(false);
    }
  }, [selectedChat, user]);

  return (
    <div className="flex flex-col h-screen relative" ref={chatDetailsRef}>
      <ChatHeader
        selectedChat={selectedChat}
        onProfileClick={onProfileClick}
        setShowSearchPopup={setShowSearchPopup}
        onClearChat={handleClearChat}
        onBlockContact={handleBlockContact}
        onUnblockContact={handleUnblockContact}
        isBlocked={isBlocked}
        isMobile={isMobile}
        onBackClick={onBackClick}
        user={user}
      />

      {showSearchPopup ? (
        <SearchPopup
          onClose={() => setShowSearchPopup(false)}
          messages={messages}
        />
      ) : (
        <>
          {pinnedMessages.length > 0 &&
            pinnedMessages.map((msg, index) => (
              <PinnedMessageDialog
                key={index}
                message={pinnedMessages[currentPinnedIndex]}
                onUnpin={() =>
                  handleUnpinMessage(pinnedMessages[currentPinnedIndex]._id)
                }
                onNext={handleNextPinnedMessage}
                onPrev={handlePrevPinnedMessage}
                isFirst={currentPinnedIndex === 0}
                isLast={currentPinnedIndex === pinnedMessages.length - 1}
              />
            ))}
          <div
            className={`bg-[#0a131a] bg-[url('assets/images/bg.webp')] bg-contain overflow-y-scroll h-full transition-all duration-300 ease-in-out scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800`}
            style={{
              padding: "12px 3%",
              marginTop: pinnedMessages.length > 0 ? "76px" : "0px",
            }}
          >
            {messages.map((msg, index) => {
              const previousMessage = index > 0 ? messages[index - 1] : null;

              return (
                <Message
                  key={uuidv4()}
                  message={msg}
                  msg={msg.msg}
                  mId={msg._id}
                  poll={msg.poll}
                  time={msg.time}
                  sent={msg.sent}
                  files={msg.files}
                  img={msg.img}
                  audio={msg.audio}
                  reactions={msg.reactions}
                  profileImage={msg.senderProfilePicture}
                  isPinned={msg.isPinned}
                  onPinMessage={() => handlePinMessage(index, "24h")}
                  onUnpinMessage={() => handleUnpinMessage(msg._id)}
                  onReplyMessage={() => handleReply(msg)}
                  onDeleteMessage={(option) =>
                    handleDeleteMessage(index, option)
                  }
                  onEditMessage={(newMessage) =>
                    updateMessage(index, newMessage)
                  }
                  previousMessage={previousMessage}
                />
              );
            })}

            {/* {messages.map((msg, index) => (
              <Message
                key={uuidv4()}
                message={msg}
                msg={msg.msg}
                mId={msg._id}
                poll={msg.poll}
                time={msg.time}
                sent={msg.sent}
                files={msg.files}
                img={msg.img}
                audio={msg.audio}
                reactions={msg.reactions}
                profileImage={msg.senderProfilePicture}
                isPinned={msg.isPinned}
                onPinMessage={() => handlePinMessage(index, "24h")}
                onUnpinMessage={() => handleUnpinMessage(msg._id)}
                onReplyMessage={() => handleReply(msg)}
                onDeleteMessage={(option) => handleDeleteMessage(index, option)}
                onEditMessage={(newMessage) => updateMessage(index, newMessage)}
              />
            ))} */}
            <div ref={bottomRef}></div>
          </div>

          {showAttachmentDialog && (
            <AttachmentDialog
              onFileSelect={handleFileSelect}
              onSendPoll={handleSendPoll}
              onSendContacts={handleSendContacts}
              onClose={() => setShowAttachmentDialog(false)}
              conversationId={selectedChat._id}
            />
          )}

          {showContactsList && (
            <Contacts
              onSelectContacts={handleSendContacts}
              onClose={() => setShowContactsList(false)}
            />
          )}

          {!isBlocked ? (
            <ChatInput
              onEmojiClick={() => {}}
              onEmojiSelect={handleEmojiSelect}
              onAttachmentToggle={() => setShowAttachmentDialog(true)}
              onInputSubmit={handleInputSubmit}
              onVoiceMessageSubmit={handleVoiceMessageSubmit}
              typing={setTyping}
              inputRef={inputRef}
              replyingTo={replyingTo}
            />
          ) : (
            <div className="p-4 text-center text-gray-500">
              This contact is blocked you.
            </div>
          )}
        </>
      )}

      {showProfilePicDialog && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-70 z-50">
          <img
            src={profilePic}
            alt="Profile"
            className="max-w-[80%] max-h-[80%] rounded-lg"
          />
        </div>
      )}

      {showConfirmationDialog && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <ConfirmationDialog
            title={
              actionType === "clearChat"
                ? "Clear Chat"
                : actionType === "block"
                ? "Block Contact"
                : "Unblock Contact"
            }
            message={
              actionType === "clearChat"
                ? "Are you sure you want to clear the chat?"
                : actionType === "block"
                ? "Are you sure you want to block this contact?"
                : "Are you sure you want to unblock this contact?"
            }
            onConfirm={confirmAction}
            onCancel={cancelAction}
          />
        </div>
      )}
    </div>
  );
}
export default ChatDetail;
