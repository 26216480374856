
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useAuthContext } from './AuthContext'; // Assuming you have an AuthContext set up

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { user } = useAuthContext();  // Retrieve the current logged-in user
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (user) {
      // Establish socket connection to the backend
      const newSocket = io('https://developer.1xstarz.com', {
        withCredentials: true,  // Allow credentials for CORS
      });

      // Log when the socket connects and show the socket ID
      newSocket.on('connect', () => {
        console.log('Socket connected:', newSocket.id);
      });

      // Log any socket handshake details after connection (Note: this only shows on the server)
      newSocket.on('connect', () => {
        console.log('Handshake details:', newSocket.io.opts);  // You can view query parameters, etc.
      });

      // Handle socket disconnection
      newSocket.on('disconnect', (reason) => {
        console.log(`Socket disconnected: ${reason}`);
      });

      // Emit 'userConnected' event with the current user's ID
      newSocket.emit('userConnected', user._id);

      // Update online users whenever the server sends the latest list
      newSocket.on('onlineUsers', (users) => {
        setOnlineUsers(users);
      });

      setSocket(newSocket);  // Store the connected socket instance

      // Cleanup socket connection when component unmounts or user changes
      return () => {
        newSocket.disconnect();
      };
    }
  }, [user]);

  return (
    <SocketContext.Provider value={{ socket, onlineUsers }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook to easily use the socket context in components
export const useSocketContext = () => useContext(SocketContext);
