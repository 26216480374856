import React, { useState, useEffect, useContext } from "react";
import LeftMenu from "../components/LeftMenu";
import ChatDetail from "../components/ChatDetail";
import DefaultPage from "../components/DefaultPage";
import LoadingScreen from "../components/LoadingScreen";
import { AuthContext } from "../context/AuthContext";
import UserDetails from "../components/UserDetails";
import "react-modern-drawer/dist/index.css";
import { ConversationContext } from "../context/ConversationContext";

function ChatWeb() {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const { user } = useContext(AuthContext);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  const { userConversations, fetchUserConversations } =
    useContext(ConversationContext);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [chatsUsers, setChatsUsers] = useState([]);

  const addNewChat = () => {
    fetchUserConversations(user._id);
  };

  const updateChatList = () => {
    fetchUserConversations(user._id);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    if (isMobile) setShowLeftMenu(false);
  };

  const handleProfileClick = () => {
    setShowUserDetails(true);
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      if (progress >= 100) setLoading(false);
      else {
        const increment = Math.floor(Math.random() * (10 + 1)) + 7;
        setProgress(progress + increment);
      }
    }, 300);
    return () => clearTimeout(id);
  }, [progress]);

  return (
    <>
      {loading ? (
        <LoadingScreen progress={progress} />
      ) : (
        <div className="w-screen h-screen overflow-hidden">
          <div className="flex justify-start items-center bg-[#111a21] h-screen">
            {/* Left Menu */}
            <div
              className={`${
                isMobile && !showLeftMenu ? "hidden" : "block"
              } bg-[#111a21] min-w-[340px] max-w-[500px] w-full h-full`}
            >
              <LeftMenu
                onChatSelect={handleChatSelect}
                userEmail={user?.username}
                user={user}
                chatsUsers={userConversations}
              />
            </div>

            {/* Chat Detail or Default Page */}
            <div
              className={`${
                isMobile && showLeftMenu ? "hidden" : "block"
              } bg-[#222f35] w-full h-full`}
            >
              {selectedChat ? (
                <div className="flex-1 h-full">
                  {showUserDetails ? (
                    <UserDetails
                      chat={selectedChat}
                      onClose={handleCloseUserDetails}
                    />
                  ) : (
                    <ChatDetail
                      onProfileClick={handleProfileClick}
                      selectedChat={selectedChat}
                      updateChatList={updateChatList}
                      addNewChat={addNewChat}
                      isMobile={isMobile}
                      onBackClick={() => setShowLeftMenu(true)}
                    />
                  )}
                </div>
              ) : (
                <DefaultPage />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatWeb;

// import React, { useState, useEffect, useContext } from "react";
// import LeftMenu from "../components/LeftMenu";
// import ChatDetail from "../components/ChatDetail";
// import DefaultPage from "../components/DefaultPage";
// import LoadingScreen from "../components/LoadingScreen";
// import { AuthContext } from "../context/AuthContext";
// import AllContact from "../components/AllContact";
// import UserDetails from "../components/UserDetails";
// import Drawer from "react-modern-drawer";
// import "react-modern-drawer/dist/index.css";
// import { ConversationContext } from "../context/ConversationContext";

// function ChatWeb() {
//   const [progress, setProgress] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [selectedChat, setSelectedChat] = useState(null);
//   const { user } = useContext(AuthContext);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [showLeftMenu, setShowLeftMenu] = useState(true);
//   const { userConversations, fetchUserConversations } =
//     useContext(ConversationContext);

//   const toggleDrawer = () => {
//     setIsDrawerOpen((prevState) => !prevState);
//   };

//   const [showUserDetails, setShowUserDetails] = useState(false);
//   const [chatsUsers, setChatsUsers] = useState([]);

//   const addNewChat = () => {
//     fetchUserConversations(user._id);
//   };
//   const updateChatList = (chatId, lastMessage, lastMessageTime) => {
//     setChatsUsers((prevChats) =>
//       prevChats.map((chat) =>
//         chat._id === chatId ? { ...chat, lastMessage, lastMessageTime } : chat
//       )
//     );
//   };

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth <= 768) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };
//     handleResize(); // Set initial state
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleChatSelect = (chat) => {
//     setSelectedChat(chat);
//     if (isMobile) setShowLeftMenu(false);
//   };

//   const handleProfileClick = () => {
//     setShowUserDetails(true);
//   };

//   const handleCloseUserDetails = () => {
//     setShowUserDetails(false);
//   };

//   const handleToggleAllUsersColumn = () => {
//     toggleDrawer();
//   };

//   useEffect(() => {
//     const id = setTimeout(() => {
//       if (progress >= 100) setLoading(false);
//       else {
//         const increment = Math.floor(Math.random() * (10 + 1)) + 7;
//         setProgress(progress + increment);
//       }
//     }, 300);
//     return () => clearTimeout(id);
//   }, [progress]);

//   return (
//     <>
//       {loading ? (
//         <LoadingScreen progress={progress} />
//       ) : (
//         <div className="w-screen h-screen overflow-hidden">
//           <div className="flex justify-start items-center bg-[#111a21] h-screen">
//             {/* Left Menu */}
//             <div
//               className={`${
//                 isMobile && !showLeftMenu ? "hidden" : "block"
//               } bg-[#111a21] min-w-[340px] max-w-[500px] w-full h-full`}
//             >
//               <LeftMenu
//                 onChatSelect={handleChatSelect}
//                 userEmail={user?.username}
//                 user={user}
//                 toggleAllUsersColumn={handleToggleAllUsersColumn}
//                 chatsUsers={userConversations}
//               />
//             </div>

//             {/* Drawer for AllContact */}
//             <Drawer
//               open={isDrawerOpen}
//               onClose={toggleDrawer}
//               direction="left"
//               style={{
//                 width: "260px",
//                 backgroundColor: "RGB(34, 83, 87)",
//               }}
//             >
//               <AllContact user={user} toggleDrawer={toggleDrawer} />
//             </Drawer>

//             {/* Chat Detail or Default Page */}
//             <div
//               className={`${
//                 isMobile && showLeftMenu ? "hidden" : "block"
//               } bg-[#222f35] w-full h-full`}
//             >
//               {selectedChat ? (
//                 <div className="flex-1 h-full">
//                   {showUserDetails ? (
//                     <UserDetails
//                       chat={selectedChat}
//                       onClose={handleCloseUserDetails}
//                     />
//                   ) : (
//                     <ChatDetail
//                       onProfileClick={handleProfileClick}
//                       selectedChat={selectedChat}
//                       updateChatList={updateChatList}
//                       addNewChat={addNewChat}
//                       isMobile={isMobile}
//                       onBackClick={() => setShowLeftMenu(true)}
//                     />
//                   )}
//                 </div>
//               ) : (
//                 <DefaultPage />
//               )}
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default ChatWeb;
