import React from "react";
import defaultProfilePic from "../assets/images/cs1.png";

function AllContacts({ pp, contact, phone, active, onClick }) {
  const profilePic = pp ? pp : defaultProfilePic;

  return (
    <div
      onClick={onClick}
      className={`flex justify-between items-center cursor-pointer w-100 h-[85px] px-3 hover:bg-[#202d33] ${
        active ? "bg-[#202d33]" : ""
      }`}
    >
      <img
        src={profilePic}
        alt="profile_picture"
        className="rounded-full w-[50px] mr-5"
      />
      <div className="flex justify-between border-t border-neutral-700 w-100 h-100 py-3">
        <div className="flex flex-col justify-between text-white">
          <h1 className="font-medium mb-1">{contact}</h1>
          <p className={`text-sm`}>{phone}</p>
        </div>
      </div>
    </div>
  );
}

export default AllContacts;
