import React, { useState, useEffect } from "react";
import Chat from "./Chat";
import { imgUrl } from "../utils/services";

const ContactsList = ({
  chatsUsers,
  search,
  handleChatClick,
  selectedChat,
  isGroupMode,
  handleSelectContact,
  selectedContacts,
}) => {
  const [filteredChats, setFilteredChats] = useState([]);

  useEffect(() => {
    if (chatsUsers.length > 0) {
      const validChats = chatsUsers.filter((chat) => chat.username);

      const sortedChatsData = validChats.sort((a, b) =>
        a.username.localeCompare(b.username)
      );

      const filteredChats = sortedChatsData.filter((chat) =>
        chat.username.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredChats(filteredChats);
    }
  }, [search, chatsUsers]);

  const groupedChats = filteredChats.reduce((acc, chat) => {
    const firstLetter = chat.username.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(chat);
    return acc;
  }, {});

  if (filteredChats.length === 0) return <div>No contacts found</div>;

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100 scrollbar-none">
      <div className="flex flex-col space-y-2 mt-1 mb-4">
        {Object.keys(groupedChats).map((letter) => (
          <div key={letter}>
            <div className="text-[#8796a1] text-sm font-light px-2 py-0 mb-0 bg-[#202d33]">
              {letter}
            </div>
            {groupedChats[letter].map((chat) => (
              <div
                key={chat._id}
                className="flex items-center justify-between px-2 py-1"
              >
                <Chat
                  // pp={`${imgUrl}${chat.profilePicture}`}
                  pp={
                    chat.profilePicture
                      ? `${imgUrl}${chat.profilePicture}`
                      : `https://avatar.iran.liara.run/username?username=${chat.chatName}`
                  }
                  contact={chat.username}
                  active={selectedChat === chat._id}
                  onClick={() => handleChatClick(chat._id)}
                  context="contactsList"
                />
                {isGroupMode && (
                  <input
                    type="checkbox"
                    checked={selectedContacts.includes(chat._id)}
                    onChange={() => handleSelectContact(chat._id)}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsList;
