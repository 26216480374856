import React, { useEffect, useState, useRef } from "react";

const DropDownMenu = ({ options, position, onClose }) => {
  const [dropdownPosition, setDropdownPosition] = useState(position);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const adjustPosition = () => {
      if (dropdownRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let newTop = position.top;
        let newLeft = position.left;

        if (dropdownRect.bottom > viewportHeight) {
          newTop = position.top - dropdownRect.height;
        }

        if (dropdownRect.right > viewportWidth) {
          newLeft = position.left - dropdownRect.width;
        }

        setDropdownPosition({ top: newTop, left: newLeft });
      }
    };

    adjustPosition();
  }, [position]);

  return (
    <div
      ref={dropdownRef}
      className="absolute bg-[#202d33] text-white rounded-lg shadow-lg z-10 w-48"
      style={{
        top: dropdownPosition.top,
        left: dropdownPosition.left,
      }}
    >
      {options.map((option, index) => (
        <div
          key={index}
          className="px-4 py-2 hover:bg-[#3c454c] cursor-pointer"
          onClick={() => {
            option.onClick();
            onClose();
          }}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default DropDownMenu;
