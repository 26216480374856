import React from "react";
import { LuPinOff } from "react-icons/lu";
import {
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
} from "react-icons/md";

function PinnedMessageDialog({
  message,
  onClose,
  onUnpin,
  onNext,
  onPrev,
  isLast,
  isFirst,
}) {
  if (!message || !message.msg) {
    return null;
  }

  return (
    <div className="absolute top-14 left-0 right-0 bg-[#111a21] p-2 z-10 w-full flex items-center justify-between">
      {/* Container for up and down arrows */}
      <div className="flex flex-col items-center space-y-2">
        {/* Up arrow */}
        <button
          onClick={onPrev}
          disabled={isFirst}
          className={`text-white ${
            isFirst ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <MdKeyboardDoubleArrowUp size={24} />
        </button>
        {/* Down arrow */}
        <button
          onClick={onNext}
          disabled={isLast}
          className={`text-white ${
            isLast ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <MdKeyboardDoubleArrowDown size={24} />
        </button>
      </div>

      {/* Message display: ensure it's a string or properly converted */}
      <div className="flex-1 text-center">
        <p className="text-white overflow-auto">{message.msg}</p>
      </div>

      <div className="flex items-center space-x-2">
        {/* Unpin button with icon */}
        <button
          onClick={() => onUnpin(message._id)}
          className="text-gray-400 mr-4"
        >
          <LuPinOff size={24} />
        </button>
      </div>
    </div>
  );
}

export default PinnedMessageDialog;
