import React, { useState, useEffect } from "react";
import { IoCall, IoVideocamOff, IoVideocam } from "react-icons/io5";
import { BsMicMuteFill, BsMic } from "react-icons/bs";

const CallingPopup = ({
  onEndCall,
  isVideoCall,
  mediaElement,
  toggleVideo,
}) => {
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (mediaElement) {
      mediaElement.srcObject
        .getAudioTracks()
        .forEach((track) => (track.enabled = !isMuted));
    }
  }, [isMuted, mediaElement]);

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg text-center relative">
        <h2 className="text-xl font-bold mb-2">
          {isVideoCall ? "Video Calling..." : "Voice Calling..."}
        </h2>
        <p>Connecting to the {isVideoCall ? "video" : "voice"} call...</p>
        <div className="flex justify-center items-center space-x-4 mt-4">
          <button className="text-red-500 text-2xl" onClick={onEndCall}>
            <IoCall />
          </button>
          <button
            className={`text-2xl ${
              isMuted ? "text-gray-500" : "text-blue-500"
            }`}
            onClick={handleMuteToggle}
          >
            {isMuted ? <BsMicMuteFill /> : <BsMic />}
          </button>
          <button className="text-2xl text-blue-500" onClick={toggleVideo}>
            {isVideoCall ? <IoVideocamOff /> : <IoVideocam />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallingPopup;
