import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SignUpProfilePage from "../components/SignUpProfile/SignUpProfilePage.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { createGlobalStyle } from "styled-components";
import { FaKey } from "react-icons/fa6";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import PasswordStrengthMeter from "../components/SignUpProfile/PasswordStrengthMeter.jsx";
import { baseUrl } from "../utils/services";

const GlobalStyles = createGlobalStyle`
  @layer utilities {
    .bg-animated {
      background-size: 400% 400%;
      animation: gradientAnimation 10s ease infinite;
    }
    @keyframes gradientAnimation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    .whatsapp-icon {
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }
  }
`;

const Signup = () => {
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [timer, setTimer] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setShowPasswordStrength(true);
    } else {
      setShowPasswordStrength(false);
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    console.log('Attempting to send OTP to:', mobile);
    try {
      const response = await axios.post(`${baseUrl}/auth/signup`,  { mobile });
      console.log('Signup response:', response.data);
      setOtpSent(true);
      setMessage(`OTP has been sent to your mobile ${mobile}.`);
      setError(false);
      startTimer(600);  // 10 minutes
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage(error.response?.data?.message || 'Failed to send OTP. Please try again.');
      setError(true);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    console.log('Attempting to verify OTP for mobile:', mobile);
    console.log('Entered OTP:', otp);

    if (!isStrongPassword) {
      setMessage('Password is not strong enough.');
      setError(true);
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      setError(true);
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/auth/verify-otp`,  { mobile, otp, password });
      console.log('OTP verification response:', response.data);
      localStorage.setItem('token', response.data.token);
      setUserId(response.data._id);
      setMessage('OTP verified successfully. Signup complete.');
      setOtpVerified(true);
      setError(false);
    } catch (error) {
      console.error('Error response:', error.response);
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
      setError(true);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const startTimer = (seconds) => {
    console.log('Starting OTP expiration timer for:', seconds, 'seconds');
    let timeLeft = seconds;
    const interval = setInterval(() => {
      timeLeft--;
      setTimer(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(interval);
        setOtpSent(false);
        setMessage('OTP expired. Please request a new one.');
        console.log('OTP expired');
        setError(true);
      }
    }, 1000);
  };

  const handleProfileUpdate = () => {
    console.log('Profile updated successfully. Navigating to /chatweb...');
    navigate('/chatweb');
  };


  return (
    <div
      className="flex justify-center items-center min-h-screen bg-[url('assets/images/lBG.png')] bg-cover bg-center"
      style={{ padding: '10px 10%' }}
    >
      <div className="absolute inset-0 bg-gray-500 opacity-30"></div>
      <GlobalStyles />
      <div className="absolute top-6 right-4">
        <FontAwesomeIcon icon={faWhatsapp} className="text-green-900 text-6xl whatsapp-icon bg-gray-100 rounded-full p-3" />
      </div>

      {!otpVerified ? (
        <form
          onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}
          className="relative bg-gray-900 bg-animated bg-opacity-80 backdrop-blur-md p-8 rounded-lg shadow-lg max-w-md w-full overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-gray-600">
          <div className="absolute inset-0 bg-gray-800 bg-opacity-80 rounded-lg"></div>

          <div className="relative z-10">
            <h2 className="text-4xl font-semibold mb-6 text-center bg-gradient-to-r from-green-400 to-emerald-500 text-transparent bg-clip-text transform transition-transform duration-300 ease-out hover:scale-105">
              Signup
            </h2>

            <div className="relative mb-4">
              <input
                type="text"
                placeholder="Enter your mobile number"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                  console.log('Mobile number updated:', e.target.value);
                }}
                required
                className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                <FaPhone size={20} />
              </span>
            </div>


            {otpSent && (
              <div className="relative mb-4">
                <input
                  type="text"
                  placeholder="Enter the OTP"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    console.log('OTP updated:', e.target.value);
                  }}
                  required
                  className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                />
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                  <FaKey size={20} />
                </span>
              </div>
            )}

            {otpSent && !otpVerified && (
              <>
                <div className="relative mb-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Create a password"
                    value={password}
                    onChange={handlePasswordChange}
                    onFocus={() => setShowPasswordStrength(true)}
                    onBlur={() => setShowPasswordStrength(password.length > 0)}

                    required
                    className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                  />
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                    <FaLock size={20} />
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-6 cursor-pointer" onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEye size={20} color="white" /> : <FaEyeSlash size={20} color="white" />}
                  </span>
                </div>

                {showPasswordStrength && <PasswordStrengthMeter password={password} setIsStrongPassword={setIsStrongPassword} />}

                <div className="relative mb-4">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    className="w-full p-6 bg-gray-800 bg-opacity-50 rounded-lg border border-gray-700 focus:border-green-500 focus:ring-2 focus:ring-green-500 text-white placeholder-gray-400 transition duration-200 pl-10"
                  />
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                    <FaLock size={20} />
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-6 cursor-pointer" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <FaEye size={20} color="white" /> : <FaEyeSlash size={20} color="white" />}
                  </span>
                </div>
              </>
            )}

            <button
              className="w-full bg-gradient-to-r from-green-500 to-emerald-600 text-white py-3 rounded-lg font-bold hover:from-green-600 hover:to-emerald-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-900 transition duration-200"
              type="submit"
            >
              {otpSent ? 'Verify OTP' : 'Send OTP'}
            </button>

            {message && (
              <p className={`mt-4 text-center ${error ? 'text-red-400' : 'text-green-500'}`}>
                {message} {timer > 0 && `OTP Expires in ${Math.floor(timer / 60)}:${timer % 60}`}
              </p>
            )}


            <div className="mt-6 text-start p-6">
              <p className="text-gray-100">Already have an account?{" "}
                <button onClick={() => navigate('/login')} className="text-green-400 hover:underline">
                  Login
                </button>
              </p>
            </div>
          </div>
        </form>
      ) : (
        <SignUpProfilePage userId={userId} onProfileUpdate={handleProfileUpdate} />
      )}
    </div>
  );
};

export default Signup;
