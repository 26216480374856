import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseUrl, postFormDataRequest } from "../utils/services";
import { useSocketContext } from "./SocketContext";
import { AuthContext } from "./AuthContext";
export const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [userConversations, setUserConversations] = useState([]);
  const [usersWithoutConversation, setUsersWithoutConversation] = useState([]);
  const { socket } = useSocketContext();
  // Fetch user conversations
  useEffect(() => {
    if (user) {
      fetchUserConversations(user._id);
    }
  }, [user]);

  useEffect(() => {
    fetchUsersWithoutConversation();
  }, [userConversations]);

  const fetchUsersWithoutConversation = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users/withoutConversation`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setUsersWithoutConversation(response.data);
      } else {
        console.error(
          "Error fetching users without conversation:",
          response.data.error
        );
      }
    } catch (error) {
      console.error(
        "Error fetching users without conversation:",
        error.message
      );
    }
  };

  const fetchUserConversations = async (userId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/conversations/getConversations/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUserConversations(response.data.conversations);
    } catch (error) {
      console.error("Error fetching conversations context:", error);
    }
    console.log("fetchUserConversations called.");
  };

  const refreshConversations = async () => {
    if (user) {
      await fetchUserConversations(user._id);
    }
    console.log("refreshConversations called.");
  };

  // Create a new conversation
  const addUserToConversation = async (selectedUserId) => {
    try {
      const response = await axios.post(
        `${baseUrl}/conversations/addUserToConversation`,
        {
          userId: user._id,
          selectedUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        const newConversation = response.data.conversation;

        // Emit new conversation event through socket
        socket.emit("newConversation", newConversation);

        // Refresh conversations after the new one is added
        await refreshConversations();

        return response.data;
      } else {
        console.error(
          "Error adding user to conversation:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error adding user to conversation:", error.message);
    }
    console.log("addUserToConversation called.");
  };

  // Create a group conversation
  const createGroup = async (contacts, groupName, chatIcon) => {
    const userId = user._id;

    const formData = new FormData();
    formData.append("userId", userId);

    if (Array.isArray(contacts)) {
      contacts.forEach((id) => {
        formData.append("participantIds[]", id);
      });
    }

    formData.append("chatName", groupName);
    if (chatIcon) {
      formData.append("chatIcon", chatIcon.file);
    }

    try {
      const response = await postFormDataRequest(
        "/conversations/createGroupConversation",
        formData
      );
      if (!response.error) {
        console.log("Group created: ", response);

        // Emit new conversation event through socket
        socket.emit("newConversation", response.conversation);

        await refreshConversations();

        return response.conversation;
      } else {
        console.error("Error creating group:", response.message);
      }
    } catch (error) {
      console.error("Error creating group:", error);
    }
    console.log("createGroup called.");
  };
  useEffect(() => {
    if (socket) {
      socket.on("newConversation", (conversation) => {
        setUserConversations((prevConversations) => [
          conversation,
          ...prevConversations,
        ]);
      });

      socket.on("newGroupConversation", (conversation) => {
        setUserConversations((prevConversations) => [
          conversation,
          ...prevConversations,
        ]);
      });

      return () => {
        socket.off("newConversation");
        socket.off("newGroupConversation");
      };
    }
  }, [socket]);

  const fetchRefresh = () => {
    fetchUserConversations(user._id);
    console.log("fetchRefresh called.");
  };

  return (
    <ConversationContext.Provider
      value={{
        userConversations,
        fetchRefresh,
        addUserToConversation,
        createGroup,
        fetchUserConversations,
        usersWithoutConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};
