import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { ConversationProvider } from "./context/ConversationContext";
import { SocketProvider } from './context/SocketContext';
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <SocketProvider>
        <ConversationProvider>
          <App />
        </ConversationProvider>
        </SocketProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './App';
// import { AuthProvider } from './context/AuthContext';
// import { BrowserRouter as Router } from 'react-router-dom';
// import './index.css';
// ReactDOM.render(
//   <Router>
//     <AuthProvider>
//       <App />
//     </AuthProvider>
//   </Router>,
//   document.getElementById('root')
// );
