import React from "react";

function StatusTicks({ status }) {
  return (
    <div className="flex items-center">
      <svg
        data-testid="tick-icon"
        xmlns="http://www.w3.org/2000/svg"
        className={`w-4 h-4 ${
          status === "read" ? "text-blue-500" : "text-[#c0c6cb]"
        }`}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ marginRight: "1px" }}
      >
        <path d="M20 6L9 17l-5-5" />
      </svg>
      {status === "read" && (
        <svg
          data-testid="tick-icon"
          xmlns="http://www.w3.org/2000/svg"
          className="w-4 h-4 text-blue-500"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20 6L9 17l-5-5" />
        </svg>
      )}
    </div>
  );
}

export default StatusTicks;
