import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Signup from './pages/SingUpPage';
import Login from './pages/LoginPage';
import ChatWeb from './pages/ChatWeb';
import { AuthContext } from './context/AuthContext'; 
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  const { isAuthenticated } = useContext(AuthContext); 

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/signup" element={!isAuthenticated ? <Signup /> : <Navigate to="/login" />} />
      <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/chatweb" />} />
      <Route path="/chatweb" element={isAuthenticated ? <ChatWeb /> : <Navigate to="/login" />} />
    </Routes>
  );
}

export default App;

