import React, { useContext, useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { MdSearch } from "react-icons/md";
import { IoVideocam } from "react-icons/io5";
import { GoArrowLeft } from "react-icons/go";
import Button from "./Common/RoundedBtn";
import Dropdown from "./Dropdown";
import { MdAddCall } from "react-icons/md";
import CallingPopup from "./CallingPopup";
import io from "socket.io-client";
import { imgUrl } from "../utils/services";
import { AuthContext } from "../context/AuthContext";
const socket = io("https://developer.1xstarz.com");

const ChatHeader = ({
  selectedChat,
  onProfileClick,
  setShowSearchPopup,
  onClearChat,
  // isBlocked,
  onBlockContact,
  onUnblockContact,
  isMobile,
  onBackClick,
  user,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCallingPopup, setShowCallingPopup] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [mediaElement, setMediaElement] = useState(null);
  const dropdownRef = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    socket.emit("userConnected", selectedChat);
    socket.on("onlineUsers", (users) => {
      setOnlineUsers(users);
    });
    return () => {
      socket.disconnect();
    };
  }, [selectedChat]);

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleVideoCall = () => {
    setShowCallingPopup(true);
    setIsVideoCall(true);
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getAudioTracks().forEach((track) => (track.enabled = false));
        const video = document.createElement("video");
        video.srcObject = stream;
        video.play();
        document.body.appendChild(video);
        video.style.position = "fixed";
        video.style.bottom = "20px";
        video.style.right = "20px";
        video.style.width = "200px";
        video.style.height = "150px";
        video.style.zIndex = "100";
        setMediaElement(video);
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  const handleCall = () => {
    setShowCallingPopup(true);
    setIsVideoCall(false);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        stream.getAudioTracks().forEach((track) => (track.enabled = false));
        const audio = document.createElement("audio");
        audio.srcObject = stream;
        audio.play();
        setMediaElement(audio);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const handleEndCall = () => {
    if (mediaElement) {
      const stream = mediaElement.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      mediaElement.remove();
    }
    setShowCallingPopup(false);
    setMediaElement(null);
  };

  const toggleVideo = () => {
    if (mediaElement && mediaElement.srcObject) {
      const videoTracks = mediaElement.srcObject.getVideoTracks();
      if (videoTracks.length > 0) {
        const videoTrack = videoTracks[0];
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoCall(videoTrack.enabled);
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            const videoTrack = stream.getVideoTracks()[0];
            mediaElement.srcObject.addTrack(videoTrack);
            videoTrack.enabled = true;
            setIsVideoCall(true);

            const video = document.createElement("video");
            video.srcObject = stream;
            video.play();
            document.body.appendChild(video);
            video.style.position = "fixed";
            video.style.bottom = "20px";
            video.style.right = "20px";
            video.style.width = "200px";
            video.style.height = "150px";
            video.style.zIndex = "100";
            setMediaElement(video);
          })
          .catch((error) => {
            console.error("Error accessing camera:", error);
          });
      }
    }
  };

  const isBlocked = selectedChat?.blockedBy?.some(
    (block) => block.blockedBy === user?._id
  );

  return (
    <div className="relative -mb-6">
      <div className="flex justify-between bg-[#202d33] h-[60px] p-3">
        <div className="flex items-center">
          {/* Back Button for Mobile */}
          {isMobile && (
            <button onClick={onBackClick} className="mr-3">
              <GoArrowLeft className="text-white text-2xl" />
            </button>
          )}

          <div
            className="flex items-center cursor-pointer"
            onClick={onProfileClick}
          >
            <img
              src={`${imgUrl}${selectedChat?.chatIcon}`}
              alt="profile_picture"
              className="rounded-full w-[45px] h-[45px] mr-5"
            />
            <div className="flex flex-col">
              <h1 className="text-white font-medium">
                {selectedChat?.chatName}
              </h1>
              <p className="text-[#8796a1] text-xs">
                {onlineUsers.some((u) => u._id === selectedChat._id)
                  ? "Online"
                  : "Offline"}
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          {/* Hide the buttons directly on mobile and move them to dropdown */}
          {!isMobile && (
            <>
              <Button icon={<MdAddCall />} onClick={handleCall} />
              <Button icon={<IoVideocam />} onClick={handleVideoCall} />
              <Button
                icon={<MdSearch />}
                onClick={() => setShowSearchPopup(true)}
              />
            </>
          )}
          <Button icon={<HiDotsVertical />} onClick={handleDropdownToggle} />
          {showDropdown && (
            <div className="absolute top-[60px] right-0 z-50" ref={dropdownRef}>
              <Dropdown
                onClearChat={onClearChat}
                onBlockContact={onBlockContact}
                onUnblockContact={onUnblockContact}
                isBlocked={isBlocked}
                options={[
                  ...(isMobile
                    ? [
                        { label: "Call", onClick: handleCall },
                        { label: "Video Call", onClick: handleVideoCall },
                        {
                          label: "Search",
                          onClick: () => setShowSearchPopup(true),
                        },
                      ]
                    : []),
                  { label: "Clear Chat", onClick: onClearChat },
                  isBlocked
                    ? { label: "Unblock Contact", onClick: onUnblockContact }
                    : { label: "Block Contact", onClick: onBlockContact },
                ]}
              />
            </div>
          )}
        </div>
      </div>

      {showCallingPopup && (
        <CallingPopup
          onEndCall={handleEndCall}
          isVideoCall={isVideoCall}
          mediaElement={mediaElement}
          toggleVideo={toggleVideo}
        />
      )}
    </div>
  );
};

export default ChatHeader;
