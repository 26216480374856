import React, { useContext, useState, useEffect } from "react";
import Chat from "./Chat";
import { AuthContext } from "../context/AuthContext";
import { ConversationContext } from "../context/ConversationContext";
import { BiFilter } from "react-icons/bi";
import { imgUrl } from "../utils/services";

const Archived = ({ onChatSelect }) => {
  const { user } = useContext(AuthContext);
  const [archiveChats, setArchivedChats] = useState([]);
  const { userConversations, fetchRefresh } = useContext(ConversationContext);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchRefresh();
  }, []);

  let filteredUsers = Array.isArray(archiveChats)
    ? archiveChats.filter(
        (archiveChat) =>
          archiveChat.chatName &&
          archiveChat.chatName.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  if (filter) {
    filteredUsers = filteredUsers.filter(
      (archiveChat) => archiveChat.unreadMsgs > 0
    );
  }

  const getArchivedConversations = (conversations, userId) => {
    const convos = conversations.filter((conversation) =>
      conversation.archivedBy?.includes(userId)
    );
    setArchivedChats(convos);
  };

  useEffect(() => {
    getArchivedConversations(userConversations, user._id);
  }, [userConversations]);

  const handleChatClick = (user) => {
    onChatSelect(user);
  };

  return (
    <div className="flex flex-col overflow-y-scroll cursor-pointer h-100">
      <h1 className="text-white text-2xl p-2 font-semibold">Archived</h1>
      <div className="flex justify-between items-center h-[60px] px-2 -mt-2 -mb-1">
        <input
          type="text"
          placeholder="Search or start a new chat"
          className="rounded-lg bg-[#202d33] text-[#8796a1] text-sm font-light outline-none p-2 px-4 py-2 w-screen h-[35px] placeholder:text-[#8796a1] placeholder:text-sm placeholder:font-light"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button
          className={`text-2xl m-2 p-1 rounded-full ${
            filter
              ? "bg-emerald-500 text-white rounded-full hover:bg-emerald-700"
              : "text-[#8796a1] hover:bg-[#3c454c]"
          }`}
          onClick={() => setFilter(!filter)}
        >
          <BiFilter />
        </button>
      </div>

      {archiveChats.length === 0 && (
        <div className="relative flex flex-col items-center justify-center h-screen w-full bg-[#111a21] overflow-y-scroll ">
          <h2 className="text-gray-500">No archived chats</h2>
        </div>
      )}

      {filteredUsers.map((archChat) => (
        <div key={archChat._id} onClick={() => handleChatClick(archChat)}>
          <Chat
            pp={
              `${imgUrl}${archChat.chatIcon}`
              // user.chatIcon
              //   ? `${imgUrl}${user.chatIcon}`
              //   : `https://avatar.iran.liara.run/username?username=${user.chatName}`
            }
            convo={archChat}
            contact={archChat.chatName || "Unknown"}
            msg={archChat.lastMessage || "No message"}
            time={
              archChat.lastMessageTime
                ? new Date(archChat.lastMessageTime).toLocaleTimeString()
                : "N/A"
            }
            unreadMsgs={archChat.unreadMsgs || 0}
            active={false}
            pinned={archChat.pinned}
          />
        </div>
      ))}
    </div>
  );
};

export default Archived;
