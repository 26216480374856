import React from "react";

const ConfirmationDialog = ({ title, message, onConfirm, onCancel }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-700 mb-6">{message}</p>
      <div className="flex justify-end space-x-2">
        <button
          className="px-4 py-2 bg-red-500 text-white rounded text-sm"
          onClick={onConfirm}
        >
          Yes
        </button>
        <button
          className="px-4 py-2 bg-gray-300 rounded text-sm"
          onClick={onCancel}
        >
          No
        </button>
      </div>
    </div>
  );
};
export default ConfirmationDialog;
