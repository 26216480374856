import React, { useState } from "react";
import { AiOutlineForm } from "react-icons/ai";
import { FcDocument, FcBusinessContact } from "react-icons/fc";
import { IoCameraReverse } from "react-icons/io5";
import DocumentPopup from "./DocumentPopup";
import PollPopup from "./PollPopup";
import ContactsList from "./ContactShareDialog";
import axios from "axios";
import { baseUrl } from "../utils/services";

const AttachmentDialog = ({
  onClose,
  onFileSelect,
  onSendPoll,
  onSendContacts,
  conversationId,
}) => {
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const [showPollPopup, setShowPollPopup] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);

  const handleActionClick = (action) => {
    if (action === "Document") {
      setShowDocumentPopup(true);
    } else if (action === "Poll") {
      setShowPollPopup(true);
    } else if (action === "Contact") {
      setShowContactPopup(true);
    } else {
      onClose();
    }
  };

  console.log(`doc: ${conversationId}`);

  const handleFileSelect = async (files) => {
    if (!files || files.length === 0) return;

    try {
      const formData = new FormData();

      // Loop through the array of files and append each file to formData
      files.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("message", ""); // Optional, can be changed to include a message

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${baseUrl}/messages/sendFiles/${conversationId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        console.log("Files uploaded successfully: ", response.data);
      } else {
        console.error("Failed to upload files: ", response.data.error);
      }
    } catch (error) {
      console.error("Error uploading files: ", error.message);
    }

    setShowDocumentPopup(false);
    onClose();
  };

  const handleCreatePoll = (poll) => {
    onSendPoll(poll);
    setShowPollPopup(false);
    onClose();
  };

  const handleShareContacts = (contacts) => {
    onSendContacts(contacts);
    setShowContactPopup(false);
    onClose();
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {showDocumentPopup && (
        <DocumentPopup
          onClose={() => setShowDocumentPopup(false)}
          onFileSelect={handleFileSelect} // Pass handleFileSelect to handle file upload
        />
      )}
      {showPollPopup && (
        <PollPopup
          onClose={() => setShowPollPopup(false)}
          onCreatePoll={handleCreatePoll}
        />
      )}
      {showContactPopup && (
        <ContactsList
          onClose={() => setShowContactPopup(false)}
          onShareContact={handleShareContacts}
        />
      )}
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        onClick={handleOverlayClick}
      ></div>
      <div className="absolute bottom-0 left-0 m-4 bg-black bg-opacity-50">
        <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
          <h2 className="text-xl font-semibold mb-4">Attachment</h2>
          <ul>
            <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Document")}
            >
              <FcDocument className="mr-2" /> Document
            </li>
            {/* <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Contact")}
            >
              <FcBusinessContact className="mr-2" /> Contact
            </li> */}
            {/* <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Camera")}
            >
              <IoCameraReverse className="mr-2" /> Camera
            </li> */}
            <li
              className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
              onClick={() => handleActionClick("Poll")}
            >
              <AiOutlineForm className="mr-2" /> Poll
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default AttachmentDialog;

// import React, { useState } from "react";
// import { AiOutlineForm } from "react-icons/ai";
// import { FcDocument, FcBusinessContact } from "react-icons/fc";
// import { IoCameraReverse } from "react-icons/io5";
// import DocumentPopup from "./DocumentPopup";
// import PollPopup from "./PollPopup";
// import ContactsList from "./ContactShareDialog";

// const AttachmentDialog = ({
//   onClose,
//   onFileSelect,
//   onSendPoll,
//   onSendContacts,
// }) => {
//   const [showDocumentPopup, setShowDocumentPopup] = useState(false);
//   const [showPollPopup, setShowPollPopup] = useState(false);
//   const [showContactPopup, setShowContactPopup] = useState(false);

//   const handleActionClick = (action) => {
//     if (action === "Document") {
//       setShowDocumentPopup(true);
//     } else if (action === "Poll") {
//       setShowPollPopup(true);
//     } else if (action === "Contact") {
//       setShowContactPopup(true);
//     } else {
//       onClose();
//     }
//   };

//   const handleFileSelect = (file) => {
//     onFileSelect(file);
//     setShowDocumentPopup(false);
//     onClose();
//   };

//   const handleCreatePoll = (poll) => {
//     onSendPoll(poll);
//     setShowPollPopup(false);
//     onClose();
//   };

//   const handleShareContacts = (contacts) => {
//     console.log("Contacts shared:", contacts);
//     onSendContacts(contacts);
//     setShowContactPopup(false);
//     onClose();
//   };

//   const handleOverlayClick = (event) => {
//     if (event.target === event.currentTarget) {
//       onClose();
//     }
//   };

//   return (
//     <>
//       {showDocumentPopup && (
//         <DocumentPopup
//           onClose={() => setShowDocumentPopup(false)}
//           onFileSelect={handleFileSelect}
//         />
//       )}
//       {showPollPopup && (
//         <PollPopup
//           onClose={() => setShowPollPopup(false)}
//           onCreatePoll={handleCreatePoll}
//         />
//       )}
//       {showContactPopup && (
//         <ContactsList
//           onClose={() => setShowContactPopup(false)}
//           onShareContact={handleShareContacts}
//         />
//       )}
//       <div
//         className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
//         onClick={handleOverlayClick}
//       ></div>
//       <div className="absolute bottom-0 left-0 m-4 bg-black bg-opacity-50">
//         <div className="bg-[#1c2730] text-white shadow-lg rounded-lg w-[300px] p-4">
//           <h2 className="text-xl font-semibold mb-4">Attachment</h2>
//           <ul>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Document")}
//             >
//               <FcDocument className="mr-2" /> Document
//             </li>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Contact")}
//             >
//               <FcBusinessContact className="mr-2" /> Contact
//             </li>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Camera")}
//             >
//               <IoCameraReverse className="mr-2" /> Camera
//             </li>
//             <li
//               className="flex items-center p-2 hover:bg-gray-700 cursor-pointer"
//               onClick={() => handleActionClick("Poll")}
//             >
//               <AiOutlineForm className="mr-2" /> Poll
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AttachmentDialog;
